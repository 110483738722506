<template>
    <v-app>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
    </v-app>
</template>

<script>
    export default {
        name: "nomaster",
        data: () => ({
            //
        }),
    };
</script>
