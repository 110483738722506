const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to sessionStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {

    init() {

    },

    getToken() {
        return sessionStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        sessionStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        sessionStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken() {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        sessionStorage.removeItem(REFRESH_TOKEN_KEY)
    },

    setSessionId() {
        let sessionId = Math.round(new Date().getTime() + '' + (Math.random() * Math.pow(10, 6)));
        sessionStorage.setItem('sessionId', sessionId);
        //console.log('sessionId ' + sessionId);
        return sessionId;
    },

    getSessionId() {
        return sessionStorage.getItem('sessionId');
    },

    setLeadId(leadId) {
        sessionStorage.setItem('leadId', leadId);
        return leadId;
    },

}

export { TokenService }

