<template>
    <v-container fluid fill-height class="py-0">
        <v-layout justify-center align-center>
            <v-flex>
                <v-row align="center" justify="center" class="hero-banner">
                    <v-col cols="12" xl="9">
                        <v-container class="hero-banner__inner text-center">
                            <v-row align="center" justify="center" v-if="mail==='appointment'">
                                <v-col cols="12" lg="7" class="pa-0">
                                    <v-img src="@/assets/images/thanks/Thanks.png"/>
                                </v-col>
                                <v-col cols="12" lg="5" class="caption text-center text-lg-left pr-md-10">
                                    <h2 class="display-2">Grazie! La tua richiesta è stata inviata</h2>
                                    <p class="title font-weight-regular my-6">
                                        Sarai ricontattato da un Agente AXA per fissare un appuntamento in agenzia.
                                    </p>
                                    <!-- button -->
                                    <v-btn x-large tile elevation="0" @click="tracciamento"
                                           class="pinkDark white--text">
                                        Calcola una nuova quotazione
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" v-if="mail==='salva'">
                                <v-col cols="12" lg="7" class="pa-0">
                                    <v-img src="@/assets/images/thanks/Thanks-quotazione.png"/>
                                </v-col>
                                <v-col cols="12" lg="5" class="caption text-center text-lg-left pr-md-10">
                                    <h2 class="display-2">Ti abbiamo inviato il riepilogo della quotazione via email:</h2>
                                    <h3 class="ellispis"> {{ getContactMail }} </h3>
                                    <p class="title font-weight-regular my-6">
                                        Se vuoi maggiori dettagli sul prodotto o se vuoi formalizzare un
                                        preventivo, lasciaci un tuo contatto telefonico. Sarai richiamato da un Agente AXA per fissare un appuntamento in agenzia.
                                    </p>
                                    <!-- button -->
                                    <v-btn x-large tile elevation="0" @click="tracciamento_salva"
                                           class="pinkDark white--text">
                                        Chiedi a un Agente
                                    </v-btn>
                                    <p class="pt-5">
                                        <a href="/axa" class="linkNuovaQuot">Fai una nuova quotazione</a>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "thanksBody",
        computed: {
            ...mapGetters({
                'getContactMail': 'params/getContactMail',
            }),
        },
        props: [
            'mail',
        ],
        data: () => ({
            idSession: sessionStorage.getItem('sessionId'),
        }),
        created() {
        },
        methods: {
            tracciamento() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'TYP_vai_a_chiedi_a_consulente',
                    'click_type': sessionStorage.getItem('sessionId')
                });
                this.$router.push({
                    name: 'home_axa'
                });
            },
            tracciamento_salva() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'TYP_vai_a_chiedi_a_consulente',
                    'click_type': sessionStorage.getItem('sessionId')
                });
                this.$router.push({
                    name: 'appuntamento_axa',
                    params: {mail: 'mail'}
                });
            },
        },
    };
</script>
