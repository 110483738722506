export default {
    state: {
        chiami: [{
            title: "Scegli tu chi sono le persone che vuoi proteggere",
            subtitle: "Con Per Noi assicuri te stesso, un’altra persona e i vostri cari, qualunque sia il vostro legame.",
            link: 'Dettagli',
            rows: [
                {
                    title: 'Chi è protetto?',
                    text: 'Per Noi, è l’innovativa polizza vita che protegge le due persone assicurate e i relativi cari da gravi imprevisti che il futuro potrebbe riservare. Un sostegno economico concreto per se stessi in caso si fosse colpiti da grave malattia o dalla perdita di autosufficienza, e per i propri cari (beneficiari) in caso di prematura scomparsa di una delle due persone assicurate.'
                },
                {
                    title: 'Come funziona?',
                    text: 'Con un unico contratto i due assicurati sono protetti in caso di prematura scomparsa, malattia grave o perdita di autosufficienza. Il capitale viene erogato al primo evento negativo che colpisca uno dei due assicurati e può essere utilizzato in totale autonomia e per qualsiasi esigenza.'
                }
            ],
            examples: [
                {
                    image: require('@/assets/images/chi_ami/dialog/dialog-img-01.png'),
                    text: 'I genitori di Matteo, un giovane universitario, sono scomparsi prematuramente in un incidente stradale'
                },
                {
                    image: require('@/assets/images/chi_ami/dialog/dialog-img-02.png'),
                    text: 'Essendo stati coinvolti entrambi, a Matteo viene liquidato il doppio del capitale assicurato'
                },
                {
                    image: require('@/assets/images/chi_ami/dialog/dialog-img-03.png'),
                    text: 'Grazie alla somma ricevuta e al sostegno dei suoi familiari, ha potuto completare i suoi studi, ottenere un master presso una prestigiosa università e gettare le basi per un futuro promettente'
                },
            ]
        }],
        assicurati: [{
            title: "Un unico premio, due assicurati",
            subtitle: "Un premio per due, bloccato per 10 anni.",
            link: 'Dettagli',
            rows: [
                {
                    title: 'Chi è assicurabile?',
                    text: 'Per Noi è la polizza vita che protegge due persone di età compresa tra i 18 e i 60 anni, in un’unica soluzione, qualunque sia il legame che le unisce: di parentela, professionale, affettivo…'
                },
                {
                    title: 'Come è calcolato il prezzo?',
                    text: 'Il premio è fisso per tutta la durata della polizza (10 anni) e può essere pagato mensilmente o annualmente a partire da 12 € al mese.</br>  \n' +
                        'Il costo della polizza (premio) dipende unicamente dalla somma delle età dei 2 assicurati e dal capitale scelto per assicurarli e rimane costante per tutta la durata del contratto. È inoltre prevista la detrazione fiscale del premio dalle imposte secondo i limiti della normativa vigente.'
                }
            ],
            examples: [
                {
                    image: require('@/assets/images/assicurati/dialog/dialog-img-01.png'),
                    text: 'Camilla e Loredana solo due sorelle inseparabili: hanno entrambe 35 anni, condividono la casa, il mutuo e possono contare esclusivamente l’una sull’altra'
                },
                {
                    image: require('@/assets/images/assicurati/dialog/dialog-img-02.png'),
                    text: 'Poco tempo fa a Loredana è stata diagnosticata una grave malattia e anche la gestione del lavoro e della quotidianità è diventata complessa'
                },
                {
                    image: require('@/assets/images/assicurati/dialog/dialog-img-03.png'),
                    text: 'Grazie alla somma di 70.000 € ricevuta e al sostegno di Camilla, Loredana è riuscita ad accedere alle migliori cure e a guarire, contribuendo anche le spese quotidiane insieme alla sorella'
                },
            ]
        }],
        protezioneDialog: [{
            title: "Per Noi",
            subtitle: "",
            text: "Con <strong>Per Noi</strong> assicuri te e un’altra persona a cui tieni, in un’unica soluzione, qualunque sia il legame che vi unisca, e proteggi il futuro delle persone a te care in caso di imprevisti gravi. Scopri la soluzione che ti permette di assicurare un <strong>capitale</strong> da €25.000 a €125.000 che <strong>verrà erogato al primo evento</strong> tra prematura scomparsa, malattia grave o perdita di autosufficienza di <strong>uno dei due assicurati</strong>. La polizza ha una durata di <strong>10 anni</strong>, con un <strong>premio fisso</strong> pagabile annualmente o mensilmente.",
            link: '+ Info'
        }],
        privacyAXA: [{
            title: "Informativa privacy",
            subtitle: "Informativa privacy",
            text: "<p><strong>Gentile Cliente, AXA Assicurazioni S.p.A. desidera fornirle le seguenti informazioni in merito al trattamento dei suoi dati personali raccolti per effettuare il preventivo della polizza.</strong></p>" +
                "<p><strong>Chi decide perché e come trattare i dati?</strong></p>" +
                "<p>AXA Assicurazioni S.p.A. (di seguito “AXA”), in qualità di Titolare autonomo del trattamento dei dati.</p>" +
                "<p><strong>Per quali scopi e su quale base i dati sono trattati?</strong></p>" +
                "<p>a) Finalità assicurativa<br/> I dati anagrafici e di contatto sono trattati per gestire la sua richiesta di procedere ad una quotazione e per attività statistiche ad essa correlate, di ricevere copia del preventivo via mail o sul telefono, di chiedere un appuntamento presso un nostro incaricato, di ricevere comunicazioni di servizio.</p>" +
                "<p><strong>Il trattamento dei dati è necessario</strong> per dare esecuzione alle misure precontrattuali da lei richieste, per adempiere ad obblighi di legge, per legittimo interesse connesso all’attività assicurativa (attività statistica). Senza i dati, non potranno essere forniti i servizi assicurativi.</p>" +
                "<p><strong>Cosa comporta il rifiuto di fornire i dati?</strong></p>" +
                "<p>b) Finalità commerciali e di marketing<br/> I dati sono trattati per fornire offerte, promozioni e soluzioni innovative e personalizzate, migliorando il livello di protezione. <strong>Il trattamento dei dati è facoltativo</strong> e possibile solo con il consenso dell’interessato liberamente revocabile. In mancanza, non vi sono effetti sui servizi assicurativi, ma l’interessato perderà l’opportunità di conoscere le nostre offerte e partecipare alle iniziative di marketing.</p>" +
                "<p><strong>A chi sono comunicati i dati?</strong></p>" +
                "<p>I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra indicati:<br/><ul><li>Dipendenti e collaboratori;</li><li>Società terze che trattano i dati per conto del Titolare;</li><li>Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori, coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti pubblici o associazioni di categoria</li><li>altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di informazione commerciale per rischi finanziari. In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie previste dalla vigente normativa.</li></ul><strong>I dati non sono ceduti a terzi, né diffusi.</strong></p>" +
                "<p><strong>Quali sono i diritti dell’interessato e come esercitarli?</strong></p>" +
                "<p>Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la portabilità dei dati e far valere il diritto all’oblio, <strong>può scrivere a</strong>: AXA Assicurazioni S.p.A. c.a. Data Protection Officer - Corso Como 17 - 20154 Milano(MI) - e-mail: <a href=\"mailto: privacy@axa.it\">privacy@axa.it</a>. Inoltre, potrà proporre reclamo al Garante Privacy <a href=\"https://www.garanteprivacy.it\">www.garanteprivacy.it</a></p>" +
                "<p><strong>Da dove provengono i dati trattati?</strong></p>" +
                "<p> I dati sono raccolti da AXA direttamente presso di lei o da terzi, anche mediante la consultazione di banche dati.</p>" +
                "<p><strong>Per quanto tempo i dati vengono conservati?</strong></p>" +
                "<p>Per la finalità assicurativa, come descritta nella presente informativa al punto a), i dati vengono conservati da AXA per non più di 24 mesi dalla raccolta. Per le attività di marketing descritte al punto b) della presente informativa, i dati saranno conservati per il tempo necessario coerente con le finalità commerciali e, comunque, per non più di 18 mesi dalla loro raccolta.</p>" +
                "<p><strong>Informazioni aggiuntive</strong></p>" +
                "<p>L’informativa completa è disponibile sul sito <a href=\"https://www.axa.it\">www.axa.it</a>. Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.</p>",
            link: 'info_outline',
            privacyLink: 'https://www.axa.it/privacy'
        }],
        privacyQuotazioneAXA: [{
            title: "Informativa privacy",
            subtitle: "Informativa privacy",
            text: "<p><strong>Gentile Cliente, AXA Assicurazioni S.p.A. desidera fornirle le seguenti informazioni in merito al trattamento dei suoi dati personali raccolti per effettuare il preventivo della polizza.</strong></p>" +
                "<p><strong>Chi decide perché e come trattare i dati?</strong></p>" +
                "<p>AXA Assicurazioni S.p.A. (di seguito “AXA”), in qualità di Titolare autonomo del trattamento dei dati.</p>" +
                "<p><strong>Per quali scopi e su quale base i dati sono trattati?</strong></p>" +
                "<p><strong>Cosa comporta il rifiuto di fornire i dati?</strong></p>" +
                "<p>a) Finalità assicurativa<br/> I dati anagrafici e di contatto sono trattati per gestire la sua richiesta di procedere ad una quotazione e per attività statistiche ad essa correlate, di ricevere copia del preventivo via mail o sul telefono, di chiedere un appuntamento presso un nostro incaricato, di ricevere comunicazioni di servizio.</p>" +
                "<p><strong>Il trattamento dei dati è necessario</strong> per dare esecuzione alle misure precontrattuali da lei richieste, per adempiere ad obblighi di legge, per legittimo interesse connesso all’attività assicurativa (attività statistica). Senza i dati, non potranno essere forniti i servizi assicurativi.</p>" +
                "<p>b) Finalità commerciali e di marketing<br/> I dati sono trattati per fornire offerte, promozioni e soluzioni innovative e personalizzate, migliorando il livello di protezione. <strong>Il trattamento dei dati è facoltativo</strong> e possibile solo con il consenso dell’interessato liberamente revocabile. In mancanza, non vi sono effetti sui servizi assicurativi, ma l’interessato perderà l’opportunità di conoscere le nostre offerte e partecipare alle iniziative di marketing.</p>" +
                "<p><strong>A chi sono comunicati i dati?</strong></p>" +
                "<p>I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra indicati: <ul><li>Dipendenti e collaboratori;</li><li>Società terze che trattano i dati per conto del Titolare;</li><li>Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori, coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti pubblici o associazioni di categoria;</li><li>Altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di informazione commerciale per rischi finanziari.<br/>In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie previste dalla vigente normativa.</li></ul><strong>I dati non sono ceduti a terzi, né diffusi.</strong></p>" +
                "<p><strong>Quali sono i diritti dell’interessato e come esercitarli?</strong></p>" +
                "<p>Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la portabilità dei dati e far valere il diritto all’oblio, <strong>può scrivere a</strong>: AXA Assicurazioni S.p.A. c.a. Data Protection Officer - Corso Como 17 – 20154 Milano (MI) - e-mail: <a href=\"mailto: privacy@axa.it\">privacy@axa.it</a>. Inoltre, potrà proporre reclamo al Garante Privacy <a href=\"https://www.garanteprivacy.it\">www.garanteprivacy.it</a></p>" +
                "<p><strong>Da dove provengono i dati trattati?</strong></p>" +
                "<p>I dati sono raccolti da AXA direttamente presso di lei o da terzi, anche mediante la consultazione di banche dati.</p>" +
                "<p><strong>Per quanto tempo i dati vengono conservati?</strong></p>" +
                "<p>Per la finalità assicurativa, come descritta nella presente informativa al punto a), i dati vengono conservati da AXA per non più di 24 mesi dalla raccolta. Per le attività di marketing descritte al punto b) della presente informativa, i dati saranno conservati per il tempo necessario coerente con le finalità commerciali e, comunque, per non più di 18 mesi dalla loro raccolta.</p>" +
                "<p><strong>Informazioni aggiuntive</strong></p>" +
                "<p>L’informativa completa è disponibile sul sito <a href=\"https://www.axa.it\" target=\"_blank\">www.axa.it</a>. Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.</p>",
            link: 'info_outline',
            privacyLink: 'https://www.axa.it/privacy'
        }],
        privacyMPS: [{
            title: "Informativa privacy",
            subtitle: "Informativa privacy",
            text: "<p><strong>Gentile Cliente, AXA MPS Assicurazioni Vita S.p.A. e Banca Monte dei Paschi di Siena S.p.A. desiderano fornirLe le seguenti informazioni in merito al trattamento dei Suoi dati personali raccolti per effettuare il preventivo della polizza.</strong></p>" +
                "<p><strong>Chi decide perché e come trattare i dati?</strong></p>" +
                "<p>AXA MPS Assicurazioni Vita S.p.A. (di seguito “AXA MPS”), in qualità di Titolare autonomo del trattamento dei dati.<br/> Si precisa che, per l’erogazione dei servizi richiesti, la Banca Monte dei Paschi di Siena S.p.A. (di seguito la “Banca”) con sede a Siena in piazza Salimbeni n. 3, è intermediario assicurativo dei prodotti di AXA MPS e opera anch’esso come Titolare autonomo del trattamento per attività di identificazione e successivo contatto, se richiesto da Lei e di identificazione (ovvero verificare se è già cliente).</p>" +
                "<p><strong>Per quali scopi e su quale base i dati sono trattati?<br/>Cosa comporta il rifiuto di fornire i dati?</strong></p>" +
                "<p>a) Finalità assicurativa<br/> I dati anagrafici e di contatto sono trattati per gestire la Sua richiesta di procedere ad una quotazione e per attività statistiche ad essa correlate.<br/>Saranno comunicati alla Banca solamente i suoi dati anagrafici, il recapito telefonico e il codice fiscale per la verifica della qualità di cliente della Banca.</p>" +
                "<p><strong>Il trattamento dei dati è necessario</strong> per dare esecuzione alle misure precontrattuali da Lei richieste, per adempiere obblighi di legge, per legittimo interesse connesso all’attività assicurativa (attività statistica). Senza i dati non potranno essere forniti i servizi assicurativi.</p>" +
                "<p>b) Finalità di contatto da parte della Banca<br/> Qualora Lei richieda di essere contattato per fissare un appuntamento in una filiale della Banca, la Compagnia provvede a comunicare a quest&#39;ultima i seguenti dati personali a Lei riferiti: nome e cognome, codice fiscale (al fine di verificare se è già nostro cliente), CAP (per cercare la filiale a Lei più vicina nel caso non sia già nostro cliente) e telefono. La Banca non acquisisce alcuna informazione inerente al preventivo della polizza che ha effettuato.<br/>Il trattamento dei suddetti dati, da parte della Banca, risulta necessario per dar seguito alla Sua richiesta di essere contattato; al riguardo Le precisiamo che per tale finalità non è richiesto il Suo preventivo consenso dal momento che la base giuridica che ne legittima il trattamento è la necessità di dar corso a Sue specifiche richieste. In tal caso il conferimento dei dati non è obbligatorio ma un eventuale rifiuto a fornirli, anche parzialmente, comporterebbe per la Banca l’impossibilità di fornire il servizio richiesto.</p>" +
                "<p><strong>A chi sono comunicati i dati?</strong></p>" +
                "<p>I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra indicati:<br/><ul><li>Dipendenti e collaboratori;</li><li>Società terze che trattano i dati per conto del Titolare; in particolare, la Banca nel caso in cui Lei chieda di essere contattato per fissare un appuntamento in una filiale;</li><li>Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori, coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti pubblici o associazioni di categoria;</li><li>altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di informazione commerciale per rischi finanziari.<br/>In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie previste dalla vigente normativa.</li></ul><strong>I dati non sono ceduti a terzi, né diffusi.</strong></p>" +
                "<p><strong>Quali sono i diritti dell’interessato e come esercitarli?</strong></p>" +
                "<p>Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la portabilità dei dati e far valere il diritto all’oblio, l’interessato può scrivere a: AXA Assicurazioni S.p.A. c.a. Data Protection Officer - Corso Como 17 - 20154 Milano(MI) - e-mail: <a href=\"mailto: privacy@axa.it\">privacy@axa.it</a>. Inoltre, potrà proporre reclamo al Garante Privacy <a href=\"https://www.garanteprivacy.it\">www.garanteprivacy.it</a></p>" +
                "<p><strong>Da dove provengono i dati trattati?</strong></p>" +
                "<p>I dati sono raccolti da AXA MPS direttamente presso l’interessato o da terzi, anche mediante la consultazione di banche dati.<br/>La Banca acquisisce dalla Compagnia esclusivamente i dati personali sopra indicati necessari per effettuare il contatto.</p>" +
                "<p><strong>Per quanto tempo i dati vengono conservati?</strong></p>" +
                "<p>I dati vengono conservati da AXA MPS per tutto il tempo necessario alla gestione del rapporto con l’interessato: decorsi 10 anni dalla cessazione del rapporto con il cliente si procede alla cancellazione dei dati.<br/> La Banca conserva i dati per il tempo necessario ad effettuare il contatto che viene effettuato entro 60 giorni trascorso tale termine i dati sono cancellati.</p>" +
                "<p><strong>Informazioni aggiuntive</strong></p>" +
                "<p>L’informativa completa è disponibile sul sito <a href=\"https://www.axa-mps.it\" target=\"_blank\">www.axa-mps.it</a>. Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.</p>" +
                "<p><strong>Responsabili della Protezione dei Dati</strong></p>" +
                "<p>Banca Monte dei Paschi di Siena S.p.A e da AXA MPS Assicurazioni Danni S.p.A, hanno istituito la figura del Responsabile della Protezione dei Dati (o Data Protection Officer – DPO) prevista dalla normativa, affidandogli compiti di informazione e consulenza per il titolare del trattamento in merito agli obblighi in materia di protezione dei dati personali e di verifica dell’attuazione ed applicazione della normativa.<br/> I DPO possono essere contattati dagli interessati per tutte le questioni relative al trattamento dei propri dati personali e per l’esercizio dei diritti previsti dal GDPR tramite i seguenti recapiti:<ul><li>per AXA MPS Assicurazioni Danni S.p.A. – c.a. Data Protection Officer – Corso Como 17 – 20154 Milano – <a href=\"mailto:privacy@axa.it\">privacy@axa.it</a></li><li>per Banca Monte dei Paschi di Siena S.p.A. PEC: <a href=\"mailto:responsabileprotezionedeidati@postacert.gruppo.mps.it\">responsabileprotezionedeidati@postacert.gruppo.mps.it</a> e mail ordinaria <a href=\"mailto:responsabileprotezionedeidati@mps.it\">responsabileprotezionedeidati@mps.it</a></li></ul></p>",
            link: 'info_outline',
            privacyLink: 'https://www.axa-mps.it/privacy'
        }],
        privacyQuotazioneMPS: [{
            title: "Informativa privacy",
            subtitle: "Informativa privacy",
            text: "<p><strong>Gentile Cliente, AXA MPS Assicurazioni Vita S.p.A. e Banca Monte dei Paschi di Siena S.p.A. desiderano fornirLe le seguenti informazioni in merito al trattamento dei Suoi dati personali raccolti per effettuare il preventivo della polizza.</strong></p>" +
                "<p><strong>Chi decide perché e come trattare i dati?</strong></p>" +
                "<p>AXA MPS Assicurazioni Vita S.p.A. (di seguito “AXA MPS”), in qualità di Titolare autonomo del trattamento dei dati.<br/> Si precisa che, per l’erogazione dei servizi richiesti, la Banca Monte dei Paschi di Siena S.p.A. (di seguito la “Banca”) con sede a Siena in piazza Salimbeni n. 3, è intermediario assicurativo dei prodotti di AXA MPS e opera anch’esso come Titolare autonomo del trattamento per attività di identificazione e successivo contatto, se richiesto da Lei e di identificazione (ovvero verificare se è già cliente).</p>" +
                "<p><strong>Per quali scopi e su quale base i dati sono trattati?</strong></p>" +
                "<p><strong>Cosa comporta il rifiuto di fornire i dati?</strong></p>" +
                "<p>a) Finalità assicurativa<br/> I dati anagrafici e di contatto sono trattati per gestire la Sua richiesta di procedere ad una quotazione e per attività statistiche ad essa correlate.<br/> Saranno comunicati alla Banca solamente i suoi dati anagrafici, il recapito telefonico e il codice fiscale per la verifica della qualità di cliente della Banca.</p>" +
                "<p><strong>Il trattamento dei dati è necessario</strong> per dare esecuzione alle misure precontrattuali da Lei richieste, per adempiere obblighi di legge, per legittimo interesse connesso all’attività assicurativa (attività statistica). Senza i dati non potranno essere forniti i servizi assicurativi.</p>" +
                "<p>b) Finalità di contatto da parte della Banca<br/> Qualora Lei richieda di essere contattato per fissare un appuntamento in una filiale della Banca, la Compagnia provvede a comunicare a quest&#39;ultima i seguenti dati personali a Lei riferiti: nome e cognome, codice fiscale (al fine di verificare se è già nostro cliente), CAP (per cercare la filiale a Lei più vicina nel caso non sia già nostro cliente) e telefono. La Banca non acquisisce alcuna informazione inerente al preventivo della polizza che ha effettuato.<br/> Il trattamento dei suddetti dati, da parte della Banca, risulta necessario per dar seguito alla Sua richiesta di essere contattato; al riguardo Le precisiamo che per tale finalità non è richiesto il Suo preventivo consenso dal momento che la base giuridica che ne legittima il trattamento è la necessità di dar corso a Sue specifiche richieste. In tal caso il conferimento dei dati non è obbligatorio ma un eventuale rifiuto a fornirli, anche parzialmente, comporterebbe per la Banca l’impossibilità di fornire il servizio richiesto.</p>" +
                "<p><strong>A chi sono comunicati i dati?</strong></p>" +
                "<p>I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra indicati:<ul><li>Dipendenti e collaboratori;</li><li>Società terze che trattano i dati per conto del Titolare; in particolare, la Banca nel caso in cui Lei chieda di essere contattato per fissare un appuntamento in una filiale;</li><li>Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori, coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti pubblici o associazioni di categoria;</li><li>Altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di informazione commerciale per rischi finanziari.<br/> In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie previste dalla vigente normativa.</li></ul><strong>I dati non sono ceduti a terzi, né diffusi.</strong></p>" +
                "<p><strong>Quali sono i diritti dell’interessato e come esercitarli?</strong></p>" +
                "<p>Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la portabilità dei dati e far valere il diritto all’oblio, l’interessato può scrivere a: AXA MPS Assicurazioni Vita S.p.A. c.a. Data Protection Officer - Corso Como 17 – 20154 Milano (MI) - e-mail: <a href=\"mailto: privacy@axa.it\">privacy@axa.it</a>. Banca Monte dei paschi di Siena S.p.A. - Staff DPO e Conformità Privacy, Via A. Moro n. 11/13 - 53100 Siena (fax + 39 0577 296520; e-mail: <a href=\"mailto:privacy@mps.it\">privacy@mps.it</a>). Inoltre, potrà proporre reclamo al Garante Privacy <a href=\"https://www.garanteprivacy.it\">www.garanteprivacy.it</a></p>" +
                "<p><strong>Da dove provengono i dati trattati?</strong></p>" +
                "<p>I dati sono raccolti da AXA MPS direttamente presso l’interessato o da terzi, anche mediante la consultazione di banche dati.<br/>La Banca acquisisce dalla Compagnia esclusivamente i dati personali sopra indicati necessari per effettuare il contatto.</p>" +
                "<p><strong>Per quanto tempo i dati vengono conservati?</strong></p>" +
                "<p>I dati vengono conservati da AXA MPS per tutto il tempo necessario alla gestione del rapporto con l’interessato: decorsi 10 anni dalla cessazione del rapporto con il cliente si procede alla cancellazione dei dati.<br/>La Banca conserva i dati per il tempo necessario ad effettuare il contatto che viene effettuato entro 60 giorni trascorso tale termine i dati sono cancellati.</p>" +
                "<p><strong>Informazioni aggiuntive</strong></p>" +
                "<p>L’informativa completa è disponibile sul sito <a href=\"https://www.axa-mps.it\" target=\"_blank\">www.axa-mps.it</a>. Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.</p>" +
                "<p><strong>Responsabili della Protezione dei Dati</strong></p>" +
                "<p>Banca Monte dei Paschi di Siena S.p.A e da AXA MPS Assicurazioni Danni S.p.A, hanno istituito la figura del Responsabile della Protezione dei Dati (o Data Protection Officer – DPO) prevista dalla normativa, affidandogli compiti di informazione e consulenza per il titolare del trattamento in merito agli obblighi in materia di protezione dei dati personali e di verifica dell’attuazione ed applicazione della normativa.<br/>I DPO possono essere contattati dagli interessati per tutte le questioni relative al trattamento dei propri dati personali e per l’esercizio dei diritti previsti dal GDPR tramite i seguenti recapiti:<ul><li>per AXA MPS Assicurazioni Danni S.p.A. – c.a. Data Protection Officer – Corso Como 17, 20154 Milano;</li><li>Per Banca Monte dei Paschi di Siena S.p.A. PEC: <a href=\"mailto:responsabileprotezionedeidati@postacert.gruppo.mps.it\">responsabileprotezionedeidati@postacert.gruppo.mps.it</a>; e mail ordinaria <a href=\"mailto:responsabileprotezionedeidati@mps.it\">responsabileprotezionedeidati@mps.it</a></li></ul></p>" ,

            link: 'info_outline',
            privacyLink: 'https://www.axa-mps.it/privacy'
        }],

    }
}
