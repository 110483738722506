const state = {
    isDisable: true,
    valid: false,
    backPremio: false,
    chiediCons: true
};

const getters = {
    getIsDisable: state => {
        return state.isDisable;
    },
    //Form.vue
    getIsFormValidate: state => {
        return state.valid;
    },
    getChiediCons: state => {
        return state.chiediCons
    }
};

const actions = {
    actionIsDisable: (context, value) => {
        context.commit('setIsDisable', value);
    },
    //Form.vue
    actionIsFormValidate: (context, value) => {
        context.commit('setIsFormValidate', value);
    },

    actionChiediCons: (context, value) => {
        context.commit('setChiediCons', value);
    }
};

const mutations = {

    setIsDisable: (state, value) => {
        state.isDisable = value;
    },

    setIsFormValidate: (state, value) => {
        state.valid = value;
    },

    setChiediCons: (state, value) => {
        state.chiediCons = value;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
