<template>
    <v-stepper :value="index" v-if="indexStepbar < 6" class="bg-transparent mx-auto">
        <v-stepper-header>
            <v-stepper-step step="1"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"></v-stepper-step>
        </v-stepper-header>
    </v-stepper>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {

        name: "stepper",
        data: () => ({
            //
        }),
        props: ['index'],
        methods: {},
        computed: {
            ...mapGetters({
                'indexStepbar': 'stepper/getIndex',
            }),
        },
    };
</script>
