<template>
    <v-container fluid class="pt-0">
        <!-- select -->
        <v-row align="center">
            <v-col cols="12" sm="6" class="text-center text-sm-right py-0">
                <p class="mb-2">Scegli l’argomento che ti interessa:</p>

            </v-col>
            <v-col cols="12" sm="6" class="text-center text-sm-left">
                <v-select v-model="filterCategory"
                          @change="tracciamento"
                          :items="categories"
                          color="AXABlue"
                          solo
                          flat
                          outlined
                          append-icon="keyboard_arrow_down">
                    <template v-slot:append>
                        <v-icon color="AXABlue">keyboard_arrow_down</v-icon>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <!-- accordion -->
        <v-row>
            <v-col cols="12">
                <v-expansion-panels
                        v-model="panel"
                        accordion
                >
                    <v-expansion-panel v-for="p in filtereditems" :key="questions.title">
                        <v-expansion-panel-header>{{p.title}}
                            <template v-slot:actions>
                                <v-icon color="AXABlue">keyboard_arrow_down</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="mb-0 answer" v-html="p.description"></p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from "vuex";

    export default {

        name: "selectDropdown",
        computed: {
            ...mapState({
                categories: state => state.domandemps.categories,
                questions: state => state.domandemps.questions,
            }),
            // accordion FaQ
            filtereditems() {
                let questions = this.questions
                if (this.filterCategory && this.filterCategory !== 'all') {
                    questions = questions.filter((p) => {
                        let foundCategory = p.category_array.findIndex((c) => {
                            return c.group === this.filterCategory
                        })
                        return foundCategory !== -1
                    })
                }

                return questions
            },
            panel: {
                set(value) {
                    if (value !== undefined) {
                        this.clickType = 'Apri';
                    } else {
                        this.clickType = 'Chiudi';
                    }
                    this.tracciamento();
                },
                get() {
                    //  return this.panel;
                }
            }
        },
        data() {
            return {
                filterCategory: 'Due persone assicurate',
                clickType: ''
            };
        },
        methods: {
            tracciamento() {
                if (this.clickType === '') {
                    this.clickType = 'Filtra ' + this.filterCategory;
                }
                window.tc_events_global(this, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'FAQ',
                    'click_type': this.clickType
                });
                this.clickType = '';
            }
        }
    };
</script>
