import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import 'intersection-observer'
import AOS from 'aos'

import MasterAXA from './layout/axa/Master'
import MasterStepAXA from './layout/axa/MasterStep'
import MasterMPS from './layout/mps/Master'
import MasterStepMPS from './layout/mps/MasterStep'
import MasterDefault from './layout/default/Master'
import NoMaster from './layout/NoMaster'
import MasterMobileAXA from './layout/axa/MasterMobile'
import MasterMobileMPS from './layout/mps/MasterMobile'

import '@babel/polyfill'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import AuthService from './services/auth.service'
import {TokenService} from './services/storage.service'
import VueHead from 'vue-head'
import "isomorphic-fetch"

import 'core-js/stable';

require('es6-promise').polyfill()

//css
import 'aos/dist/aos.css';

import VueWaypoint from 'vue-waypoint'

// Waypoint plugin
Vue.use(VueWaypoint);

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

Vue.component('master-axa-layout', MasterAXA);
Vue.component('master-step-axa-layout', MasterStepAXA);
Vue.component('master-mps-layout', MasterMPS);
Vue.component('master-step-mps-layout', MasterStepMPS);
Vue.component('master-default-layout', MasterDefault);
Vue.component('no-master-layout', NoMaster);
Vue.component('master-mobile-axa-layout', MasterMobileAXA);
Vue.component('master-mobile-mps-layout', MasterMobileMPS);

Vue.config.productionTip = false;
Vue.use(VueHead);

export const eventBus = new Vue();

new Vue({
    created() {
        AOS.init({
            once: true,
        })
    },
    router,
    store,
    vuetify,
    MasterAXA,
    MasterStepAXA,
    MasterMPS,
    MasterStepMPS,
    MasterDefault,
    NoMaster,
    MasterMobileAXA,
    MasterMobileMPS,
    render: h => h(App)
})
    .$mount('#app');

//Use the window object to make it available globally.
window.axios = require('axios');

//Set session Id
if (!sessionStorage.getItem('sessionId')) {
    TokenService.setSessionId();
}

AuthService.authentication().then(function (token) {
    if (!sessionStorage.getItem('leadId')) {
        grecaptcha.ready(function () {
            grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                window.axios.post(process.env.VUE_APP_LEAD, {
                    'sessionTkn': sessionStorage.getItem('sessionId'),
                    'type': '',
                    captcha: captchaToken
                })
                    .then(function (res) {
                        TokenService.setLeadId(res.data.data.identifier);
                    });
            });
        });
    }
});
