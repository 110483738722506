<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
    const no_master = "no-master";

    export default {
        name: "App",
        computed: {
            layout() {
                return ( this.$route.meta.layout || no_master) + '-layout';
            }
        },
        head: {
            link: [
                {
                    rel: 'icon',
                    href: '/favicons_axa/favicon-32x32.png',
                    sizes: '16x16',
                    type: 'image/png'
                }
            ],
            script: [
                {
                    type: 'text/javascript',
                    src: 'https://www.google.com/recaptcha/api.js?render=' + process.env.VUE_APP_RECAPTCHA_SITE_KEY,
                    async: true,
                    body: true
                },
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA3,
                    async: false,
                    body: true
                },
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA5,
                    async: false,
                    body: true
                }
                /*
                {
                    type: 'text/javascript',
                    inner: 'tc_events_global(this, "virtual_page", {});',
                    async: false,
                    body: true
                }
                */
            ]
        },
        data: () => ({
            //
        }),
    };
</script>
