<template>
    <v-content class="page_assicurati">
        <Stepper :index="indexStepbar" class="stepperBar"></Stepper>
        <AssicuratiBody/>
    </v-content>
</template>

<script>
    import AssicuratiBody from "@/components/axa/Assicurati_body";
    import Stepper from "@/components/partials/Stepper";
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "assicurati",
        components: {
            AssicuratiBody,
            Stepper
        },
        data() {
            return {
                showStepper: true,
                isNext: this.$route.params.isNext,
                isDisable: false,
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        created() {
            this.setIndex(2);
        },
        head: {
            title: {
                inner: 'Gli assicurati'
            },
            script: [
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA1,
                },
                {
                    type: 'text/javascript',
                    inner: 'if (typeof tc_events_global === "function") { \n' +
                           '    tc_events_global(this, "virtual_page", {});\n' +
                           '}'
                },
            ],
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        beforeMount(){
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: "Step3",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        computed: {
            ...mapGetters({
                indexStepbar: "stepper/getIndex",
                getCustomerTypeTrk: 'params/getCustomerTypeTrk',
            })
        },
        methods: {
            ...mapMutations({
                setIndex: "stepper/setIndex"
            }),
        },
    };
</script>
