<template>
    <v-app>
        <Header/>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
        <Footer/>
    </v-app>
</template>

<script>
    import Header from "@/components/base/axa/Header";
    import Footer from "@/components/base/axa/Footer";

    export default {
        name: "masterAXA",
        components: {
            Header,
            Footer,
        },
        data: () => ({
            //
        }),
    };
</script>
