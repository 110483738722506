import Home from '../views/mps/Home';
import ChiAmi from '../views/mps/ChiAmi';
import Assicurati from '../views/mps/Assicurati';
import Premio from '../views/mps/Premio';
import Appuntamento from '../views/mps/Appuntamento';
import Salva from '../views/mps/Salva';
import Thanks from '../views/mps/Thanks';

export default [
    //MPS
    {
        path: '/mps',
        name: 'home_mps',
        meta: {layout: "master-mps"},
        component: Home
    },
    {
        path: '/mps/chi_ami',
        name: 'chiAmi_mps',
        meta: {layout: "master-step-mps"},
        component: ChiAmi
    },
    {
        path: '/mps/parlaci',
        name: 'assicurati_mps',
        meta: {layout: "master-step-mps"},
        component: Assicurati
    },
    {
        path: '/mps/premio',
        name: 'premio_mps',
        meta: {layout: "master-mobile-mps"},
        component: Premio
    },
    {
        path: '/mps/appuntamento',
        name: 'appuntamento_mps',
        meta: {layout: "master-step-mps"},
        component: Appuntamento
    },
    {
        path: '/mps/salva',
        name: 'salva_mps',
        meta: {layout: "master-step-mps"},
        component: Salva
    },
    {
        path: '/mps/recupera/:token',
        name: 'recupera_mps',
        meta: {layout: "master-mps"},
        component: Appuntamento
    },
    {
        path: '/mps/thanks',
        name: 'thanks_mps',
        meta: {layout: "master-mps"},
        component: Thanks
    }
];
