<template>
    <v-footer height="auto" padless class="footer-mobile">
        <v-container fluid class="py-0 footer">
            <v-row>
                <v-col cols="12" class="AXAdarkIndigo whiteDark--text">
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                Messaggio pubblicitario con finalità promozionale. "Per Noi" è un prodotto di AXA Assicurazioni S.p.A.
                                società del Gruppo Assicurativo AXA. La quotazione è indicativa e non vincolante, non
                                costituisce né sostituisce un preventivo e/o una proposta contrattuale in quanto
                                mancante di alcuni elementi necessari all’assunzione del rischio assicurativo. Per avere
                                maggiori informazioni e un preventivo richiedi subito un appuntamento in agenzia. Prima
                                della sottoscrizione leggere il set informativo disponibile su <a
                                    href="https://www.axa.it" target="_blank">www.axa.it</a>.
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="AXAazure whiteDark--text">
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                &copy; {{ new Date().getFullYear() }} AXA Assicurazioni S.p.A | Partita IVA GRUPPO IVA AXA ITALIA n. 10534960967 -
                                <a href="https://www.axa.it/privacy" target="_blank" class="white--text">Privacy</a> -
                                <a href="https://www.axa.it/note-legali" target="_blank" class="white--text">Note legali</a> -
                                <a href="https://www.axa.it/cookies" target="_blank" class="white--text">Cookies</a>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <a href="/accessibilita" target="_blank" class="white--text">Dichiarazione di accessibilità</a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
    export default {
        name: "footerMobileAXA",
        data: () => ({
            //
        })
    };
</script>