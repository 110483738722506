<template>
    <v-row align="center" justify="center">
        <v-col cols="12" md="7" class="text-center text-md-left py-0 pr-md-10">
            <p class="mb-4"><span>{{ quotation.firstInsName }}, {{ quotation.secondInsName }}</span><br class="d-md-none"> scegliendo un capitale di</p>
            <v-row>
                <v-col cols="12" class="text-center">
                    <p class="mb-0"><span>{{ parseInt(getCapitalByValue(slider_value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} €</span></p>
                    <!--<p class="mb-0"><span>{{ //quotation.selectedCapital }} €</span></p>-->
                </v-col>
            </v-row>
            <v-slider v-model="slider_value"
                      height="20"
                      mix="0"
                      :max="slider_length"
                      step="1"
                      ticks="always"
                      tick-size="4"
                      color="pinkDark"
                      track-color="pink"
                      @end="update_quotation"
            >

            </v-slider>
            <v-row>
                <v-col cols="6" class="text-left py-0 slider-ends-text">
                    <strong>{{ parseInt(quotation.capitals[0].capital).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }}€</strong>
                </v-col>
                <v-col cols="6" class="text-right py-0 slider-ends-text">
                    <strong>{{ parseInt(quotation.capitals[quotation.capitals.length - 1].capital).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }}€</strong>
                </v-col>
            </v-row>
            <p class="my-4">garantite alla<br class="d-md-none"> <span>{{ quotation.insTypeLbl }}</span><br class="d-md-none"><br
                    class="d-md-none"> un <strong>futuro al sicuro</strong> anche
                <strong>in caso di
                    grandi imprevisti</strong> poichè potrete contare su un capitale che vi che vi sosterrà nei momenti
                di
                difficoltà.</p>
        </v-col>
        <v-col cols="12" md="5" class="text-center py-0 border-left d-none d-md-block">
            <h3 class="title">Prezzo totale per voi due</h3>
            <p class="pb-2 price"><span>{{ parseFloat(getPriceByValue(slider_value)).toFixed(2).replace(".", ",") }} €</span>/mese</p>
            <v-row>
                <v-col cols="12">
                    <!-- button 1 -->
                    <v-btn x-large tile elevation="0" @click="tracciamento"
                           class="pinkDark white--text">
                        Ti chiamiamo noi
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <!-- button ghost -->
                    <v-btn x-large tile outlined elevation="0"
                           @click="tracciamento_salva"
                           color="AXABlue"
                           class="white--text">
                        Salva quotazione
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "PremioBoxAXA",
        components: { },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
                leadId: sessionStorage.getItem('leadId'),
                slider_length: 0,
                quotation: {}
            };
        },
        created(){
            this.quotation = this.getQuotation;
            // this.capital = this.quotation.selectedCapital;
            this.slider_length = this.quotation.capitals.length - 1;
            this.setSliderValue(this.getCapitalDefaultValue());
        },
        computed: {
            ...mapGetters({
                'getQuotation': 'params/getQuotation',
                'getSliderValue': 'premio/getSliderValue',
            }),
            slider_value: {
                set(value) {
                    this.setSliderValue(value);
                },
                get() {
                    return this.getSliderValue;
                }
            },
        },
        methods: {
            ...mapActions({
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionInsType': 'params/actionInsType',
                'actionQuotationParams': 'params/actionQuotationParams',
                'actionCustomerType': 'params/actionCustomerType'
            }),
            ...mapMutations({
                'setSliderValue': 'premio/setSliderValue'
            }),
            getCapitalByValue(value){
                return this.quotation.capitals[value].capital;
            },
            getPriceByValue(value){
                return this.quotation.capitals[value].price;
            },
            getCapitalDefaultValue(){
                for (let i=0; i < this.quotation.capitals.length; i++) {
                    if(this.quotation.capitals[i].capital == this.quotation.selectedCapital)
                        return i;
                }
                return 0;
            },
            tracciamento() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Chiedi_a_consulente_step1',
                    'click_type': this.getPriceByValue(this.slider_value)
                });
                this.$router.push('/axa/appuntamento');
            },
            tracciamento_salva() {
                window.tc_events_global(this, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Salva_prospetto_step1',
                    'click_type': sessionStorage.getItem('sessionId')
                });
                this.$router.push('/axa/salva');
            },
            update_quotation(){
                if(this.quotation.capitals[this.getSliderValue].capital != this.quotation.selectedCapital){
                    let app_quotation = process.env.VUE_APP_QUOTATION + '/' + this.leadId + '/' + this.quotation.identifier;
                    let _this = this;

                    grecaptcha.ready(function () {
                        grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                            axios.post(app_quotation,
                                {
                                    insMtxSelec: _this.quotation.capitals[_this.slider_value].id,
                                    captcha: captchaToken
                                }
                            )
                            .then(resp => {
                                _this.actionQuotationParams(resp.data.data);
                                _this.quotation = resp.data.data;
                            })
                            .catch(err => {
                                console.log(err);
                            });
                        });
                    });
                }
            }
        }
    };
</script>

