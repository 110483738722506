<template>
    <div class="stickyContainer" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }">
        <v-layout
                id="sticky_desktop"
                :class="{ 'isFixed': addFixed }"
                elevation="5">
            <!-- overlay -->
            <v-overlay v-model="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <!-- sticky default -->
            <v-card width="100%" class="stickyBar" elevation="5" :class="[(goBack=='assicurati_mps'?'d-none': '')]">
                <v-container fluid class="py-0">
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="8" md="6" lg="4">
                            <v-row align="center" justify="center">
                                <!-- Indietro -->
                                <v-col cols="5" :class="['py-0 order-1', showSkip ? 'col-6' : 'col-5 text-center']">
                                    <div v-if="goBack">
                                        <v-btn text class="btn_goBack AXABlue--text"
                                               @click="btnActions(goTo, goBack, isNext, true, parentPage), hitTrack(level_2_number,click_name,click_type)">
                                            <v-icon left small class="AXABlue--text">arrow_back</v-icon>
                                            Indietro
                                        </v-btn>
                                    </div>
                                </v-col>
                                <!-- Prosegui -->
                                <v-col cols="7" class="text-center py-0 order-2">
                                    <v-btn x-large tile elevation="0"
                                           @click="btnActions(goTo, goBack, isNext, false, parentPage), hitTrack(level_2_number,click_name,click_type)"
                                           class="pinkDark white--text"
                                           :class="{hideBtnGoto : hideBtnGoto}"
                                           :disabled="isDisable">
                                        {{ btnLabel}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-layout>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions, mapMutations} from "vuex";

    export default {
        name: "stickyBarMPS",
        props: ['goTo', 'goBack', 'items', 'parentPage', 'btnLabel', 'activeBtn', 'inActiveBtn', 'showSkip', 'showStickyTop', 'heightSticky', 'hideBtnGoto', 'level_2_number', 'click_name', 'click_type'],
        data() {
            return {
                overlay: false,
                isNext: true,
                sessionId: sessionStorage.getItem('sessionId'),
                leadId: sessionStorage.getItem('leadId'),
                token: this.$route.params.token,
                intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
                },
                // ht,
                addFixed: false
            }
        },
        computed: {
            ...mapState({
                priceCapitals: state => state.cards.priceCapitals,
            }),
            ...mapGetters({
                'isDisable': 'stickyBar/getIsDisable',
                'getInsType': 'params/getInsType',
                'getQuotationId': 'params/getQuotationId',
                'getSelectedProjects': 'params/getSelectedProjects',
                'getInsuredData': 'params/getInsuredData',
                'getBeneficiariesData': 'params/getBeneficiariesData',
                'getAppointmentData': 'params/getAppointmentData',
                'getSaveData': 'params/getSaveData',
                'getIdCapitale': 'cards/getIdCapitale',
                'getPriceCapitals': 'cards/getPriceCapitals',
                'getPreviousInsType': 'params/getPreviousInsType',
                'getChiediCons': 'stickyBar/getChiediCons'
            }),
        },
        methods: {
            ...mapActions({
                'actionNextStepbar': 'stepper/actionNextStepbar',
                'actionBackStepbar': 'stepper/actionBackStepbar',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionQuotationParams': 'params/actionQuotationParams',
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionContactMail': 'params/actionContactMail',
            }),
            ...mapMutations({
                'setIndex': 'stepper/setIndex',
            }),
            btnActions(goTo, goBack, isNext, btnIndietro) {

                //INFO: A SECONDA DELLA ROTTA SUCCESSIVA INSERIRE IL CASE
                //CHE DETERMINA L'AZIONE CHE SI VUOLE ESEGUIRE AL BOTTONE
                //PER DEFAULT PASSA ALLA ROTTA SUCCESSIVA DISABILITANDO IL BOTTONE PROSEGUI

                this.showLoader();
                let _this = this;
                if (!btnIndietro) {
                    //TASTO AVANTI
                    switch (goTo) {

                        case "assicurati_mps": // button pagina chi_ami
                            let app_quotation_url = process.env.VUE_APP_QUOTATION + '/' + this.leadId;

                            if (this.getInsType != this.getPreviousInsType) {
                                grecaptcha.ready(function () {
                                    grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                                        axios.post(app_quotation_url,
                                            {
                                                insTypeSelect: _this.getInsType,
                                                captcha: captchaToken
                                            }
                                        )
                                            .then(resp => {
                                                _this.actionClearQuotation();
                                                _this.actionQuotationParams(resp.data.data);
                                                _this.actionNextStepbar();
                                                _this.actionIsDisable(false);
                                                _this.hideLoader();
                                                _this.$router.push({name: goTo, params: {isNext: isNext},});
                                            })
                                            .catch(err => {
                                                console.log(err);
                                                _this.hideLoader();
                                            });
                                    });
                                });
                            } else {
                                // Advances stepbar
                                this.actionNextStepbar();
                                this.actionIsDisable(false);
                                this.hideLoader();
                                this.$router.push({name: goTo, params: {isNext: isNext},})
                            }
                            break;

                        case "premio_mps": // button pagina imprevisti

                            let app_quotation = process.env.VUE_APP_QUOTATION + '/' + this.leadId + '/' + this.getQuotationId;

                            grecaptcha.ready(function () {
                                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                                    axios.post(app_quotation,
                                        {
                                            firstIns: _this.getInsuredData.name,
                                            firstInsDate: _this.getInsuredData.date.split("/").reverse().join("-"),
                                            secondIns: _this.getInsuredData.namePartner,
                                            secondInsDate: _this.getInsuredData.datePartner.split("/").reverse().join("-"),
                                            captcha: captchaToken
                                        }
                                    )
                                        .then(resp => {
                                            _this.actionIsDisable(false);
                                            _this.actionNextStepbar();
                                            _this.actionQuotationParams(resp.data.data);
                                            _this.hideLoader();
                                            _this.$router.push({name: goTo, params: {isNext: isNext},});
                                        })
                                        .catch(err => {
                                            _this.actionNextStepbar();
                                            _this.hideLoader();
                                            console.log(err);
                                        });
                                });
                            });

                            break;

                        case 'thanks_mps': // button save appointment
                            let appointment_url = process.env.VUE_APP_APPOINTMENT + '/' + this.leadId + '/' + this.getQuotationId;

                            grecaptcha.ready(function () {
                                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                                    axios.post(appointment_url,
                                        {
                                            firstName: _this.getAppointmentData.name,
                                            lastName: _this.getAppointmentData.lastName,
                                            mail: _this.getAppointmentData.email,
                                            cf: _this.getAppointmentData.code,
                                            tel: _this.getAppointmentData.telephone,
                                            priv: 'si',
                                            birthDate: '',
                                            zip: '',
                                            gnd: '',
                                            place_birth: '',
                                            token: _this.token,
                                            captcha: captchaToken
                                        }
                                    )
                                        .then(resp => {
                                            _this.actionNextStepbar();
                                            _this.actionIsDisable(false);
                                            _this.hideLoader();
                                            _this.$router.push({
                                                name: 'thanks_mps',
                                                params: {isNext: isNext, mail: 'appointment'},
                                            })
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            _this.hideLoader();
                                        });
                                });
                            });

                            break;

                        case 'thanks_mps_salva': // button save appointment
                            let save_url = process.env.VUE_APP_SAVE + '/' + sessionStorage.getItem('leadId') + '/' + this.getQuotationId;

                            grecaptcha.ready(function () {
                                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                                    axios.post(save_url,
                                        {
                                            firstName: _this.getSaveData.name,
                                            lastName: _this.getSaveData.lastName,
                                            mail: _this.getSaveData.email,
                                            birthDate: _this.getSaveData.date.split("/").reverse().join("-"),
                                            priv: 'si',
                                            captcha: captchaToken
                                        }
                                    )
                                    .then(resp => {
                                        _this.actionContactMail(_this.getSaveData.email);
                                        _this.actionNextStepbar();
                                        _this.actionIsDisable(false);
                                        _this.hideLoader();
                                        _this.$router.push({
                                            name: 'thanks_mps',
                                            params: {isNext: isNext, mail: 'salva'},
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        _this.hideLoader();
                                    });
                                });
                            });

                            break;

                        default:
                            this.actionIsDisable(true);
                            this.actionNextStepbar();
                            this.hideLoader();
                            this.$router.push({name: goTo, params: {isNext: isNext},})
                    }
                } else {
                    //TASTO INDIETRO
                    this.actionIsDisable(false);
                    this.actionBackStepbar();
                    this.hideLoader();
                    this.$router.push({name: goBack, params: {isNext: isNext},})
                }
            },
            hitTrack(level_2_number, click_name, click_type) {
                //console.log(level_2_number + ' ' + click_name + ' ' + click_type);
                window.tc_events_global(this, 'xt_click', {
                    'level_2_number': level_2_number,
                    'click_name': click_name,
                    'click_type': click_type
                });
            },
            goNextPage(goTo, isNext) {
                this.actionNextStepbar();
                this.$router.push({name: goTo, params: {isNext: isNext},})
            },
            showLoader() {
                this.overlay = true;
            },
            hideLoader() {
                this.overlay = false;
            },
            onWaypoint({going}) {
                if (going === this.$waypointMap.GOING_IN) {
                    console.log('in');
                    this.addFixed = false;
                }
                if (going === this.$waypointMap.GOING_OUT) {
                    console.log('out');
                    this.addFixed = true;
                }
            }
        },
    }
</script>
