<template>
    <v-container fluid class="py-0">
        <v-layout justify-center align-center>
            <v-flex>
                <!-- titolo -->
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 mx-auto">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" class="caption mb-5 pt-0">
                                            <h2 class="display-2">La tua quotazione</h2>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
                <!-- box -->
                <v-row class="px-0 fill-height">
                    <v-container fluid class="pt-0">
                        <v-row>
                            <v-col cols="12" sm="9" md="10" lg="8" xl="7" class="py-0 mx-auto">
                                <v-card class="mx-auto mb-0 text-center v-card--main">
                                    <v-card-title class="gradient-card">
                                        <div class="mx-auto imgContainer" v-if="!businessInsType">
                                            <img src="@/assets/images/premio/premio-box-icon_578x578.png" />
                                        </div>
                                        <div class="mx-auto imgContainer" v-else>
                                            <img src="@/assets/images/premio/premio-box-icon-soci_569x569.png" />
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="py-0 px-3 px-sm-12">
                                        <PremioBox/>
                                        <PremioAccordion/>
                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
                <!-- download -->
                <v-row>
                    <v-col cols="12" class="py-0 mx-auto">
                        <v-container fluid class="text-left py-0">
                            <v-row>
                                <v-col cols="12" sm="9" md="10" lg="8" xl="7" class="py-0 mb-12 mx-auto text-left">
                                    <v-btn text
                                           :ripple="false"
                                           @click="tracciamento"
                                           href="/docs/axa/Per_Noi_Set_Informativo-022020.pdf" target="_blank"
                                           class="AXABlue--text font-weight-bold text-none px-0">
                                        <v-icon class="transparent pr-2 v-icon--small">get_app</v-icon>
                                        SCARICA IL SET INFORMATIVO
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>
        <!-- sticky bar -->
        <v-row class="px-0 d-block d-lg-block">
            <StickyBarPremio/>
        </v-row>
    </v-container>
</template>

<script>

    import PremioBox from "@/components/partials/axa/PremioBox";
    import StickyBarPremio from "@/components/base/axa/StickyBarPremio";
    import PremioAccordion from "@/components/partials/axa/PremioAccordion";
    import {mapGetters, mapMutations} from "vuex";


    export default {
        name: "premioBody",
        components: {
            PremioBox,
            PremioAccordion,
            StickyBarPremio
        },
        data: () => ({
            businessInsType: false,
            //prospetto: false,
            idSession: sessionStorage.getItem('sessionId'),
        }),
        mounted() {
            this.businessInsType = this.getInsTypeData.identifier == 7;
            this.setIndetifier(this.getInsTypeData.identifier);
        },
        computed: {
            ...mapGetters({
                'getInsTypeData': "params/getInsTypeData",
            })
        },
        methods: {
            ...mapMutations({
                'setIndetifier': "params/setIndetifier",
            }),
            tracciamento() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Download_set_info',
                    'click_type': sessionStorage.getItem('sessionId')
                });
            },
        },
    };
</script>
