<template>
    <v-content class="page_home">
        <HomeBody/>
    </v-content>
</template>

<script>
    import HomeBody from "@/components/axa/Home_body";
    import {mapGetters, mapActions} from "vuex";

    export default {
        name: "home_axa",
        components: {
            HomeBody,
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        head: {
            script: [
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA1,
                },
                {
                    type: 'text/javascript',
                    inner: 'if (typeof tc_events_global === "function") { \n' +
                           '    tc_events_global(this, "virtual_page", {});\n' +
                           '}'
                },
            ],
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        beforeMount(){
            this.businessInsType = this.getInsType == 7;
            let user_category = this.getCustomerTypeTrk;

            if (this.getCustomerType == "mps" || this.getCustomerType == "") {
                this.setCustomerTypeAfterLead('axa');
                user_category = "Axa";
            }

            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: user_category,
                page_name: "Homepage",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        computed: {
            ...mapGetters({
                getCustomerType: 'params/getCustomerType',
                getCustomerTypeTrk: 'params/getCustomerTypeTrk',
            })
        },
        methods: {
            ...mapActions({
                actionCustomerType: 'params/actionCustomerType',
            }),
            setCustomerTypeAfterLead(type){
                let _this = this;
                setTimeout(function () {
                    if(sessionStorage.getItem('leadId')){
                        grecaptcha.ready(function () {
                            grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                                let query = {
                                    captcha: captchaToken
                                };
                                query.type = type;

                                let url_app_lead = process.env.VUE_APP_LEAD + '/' + sessionStorage.getItem('leadId');

                                axios.post(url_app_lead, query)
                                    .then(function (res) {
                                        _this.actionCustomerType(type);
                                    });

                            });
                        });
                    }else{
                        _this.setCustomerTypeAfterLead(type);
                    }
                }, 1000);
            }
        },
        mounted() {
        },
    };
</script>
