<template>
    <v-row>
        <v-col cols="12" class="pb-4">
            <v-dialog v-model="dialog" scrollable max-width="960" v-for="item in items" :key="item.title">
                <template v-slot:activator="{ on }">
                    <div v-on="on" @click="hitTrack(level_2_number,click_name,click_type)">
                        <v-btn text class="AXABlue--text text-uppercase">{{ item.link }}</v-btn>
                        <v-icon small class="AXABlue--text rotate180">arrow_back</v-icon>
                    </div>
                </template>
                <v-card tile class="px-3 py-5">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                class="AXABlue--text text-right"
                                :elevation="0"
                                fab
                                small
                                text
                                @click="dialog = false">
                            <v-icon dark>clear</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="text-center py-0 py-md-3">
                                <h2 class="mb-3 mb-md-6 display-1 mt-3">{{ item.title }}</h2>
                                <p>{{ item.subtitle }}</p>
                            </v-col>
                        </v-row>
                        <!-- rows -->
                        <v-row v-for="(row, index) in item.rows" :key="item.rows[index].title">
                            <v-col cols="12" class="text-center text-md-left">
                                <h3 class="mb-2">{{item.rows[index].title }}</h3>
                                <p class="mb-0" v-html="item.rows[index].text"></p>
                            </v-col>
                        </v-row>
                        <!-- colonne -->
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <h3 class="mb-6">Un esempio</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" class="text-center" v-for="(example, index) in item.examples" :key="item.image">
                                <img :src="item.examples[index].image" alt="">
                                <p class="mb-4" v-html="item.examples[index].text"></p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "Dialog",
        props: ['items', 'boxes', 'level_2_number', 'click_name', 'click_type'],
        data() {
            return {
                dialog: false,
                fullscreen: false
            };
        },
        methods: {
            hitTrack(level_2_number, click_name, click_type) {
                tc_events_global(this, 'xt_click', {
                    'level_2_number': level_2_number,
                    'click_name': click_name,
                    'click_type': click_type
                });
            }
        }
    };
</script>
