<template>
    <v-container fluid class="py-0">
        <v-layout justify-center align-center style="min-height: calc(100vh - 282px)">
            <v-flex>
                <!-- titolo con dialog -->
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 mx-auto">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" class="caption">
                                            <h2>Qual è il legame che vi unisce?</h2>
                                            <p class="title font-weight-regular mt-4 mb-0 px-lg-10">
                                                <strong>Affettivo</strong> o <strong>professionale:</strong> qualunque
                                                esso sia, Per Noi vi protegge dai grandi eventi negativi che possono
                                                accadere nella vita.
                                            </p>
                                            <Dialog :items="chiami"
                                                    :level_2_number="'AXA_PerNoi'"
                                                    :click_name="'Più_info'"
                                                    :click_type="idSession"/>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
                <!-- Card Carousel -->
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 px-0 mx-auto">
                        <slick
                                id="carousel-text"
                                :options="carouselOptions"
                                v-if="items.length"
                                ref="carousel"
                        >
                            <div
                                    v-for="item in items"
                                    class="mx-auto text-center"
                                    :class="{ active : active_el == item.identifier }"
                                    :key="item.identifier"
                                    @click="selectedItem(item.identifier)"
                            >
                                <v-avatar size="80">
                                    <v-img
                                            :src="require('@/assets/images/chi_ami/nuclei-0'+ item.identifier +'.png')"
                                    />
                                </v-avatar>
                                <v-hover
                                        v-slot:default="{ hover }"
                                        open-delay="10"
                                >
                                    <v-card class="pt-10 mb-2" height="210"
                                            :elevation="hover ? 4 : 2">
                                        <v-card-title
                                                class="mt-0 mt-sm-3 mt-md-3 justify-center title font-weight-regular"
                                                v-html="item.name"
                                        ></v-card-title>
                                        <v-card-actions class="py-0">
                                            <v-btn :ripple="false" class="mx-auto" fab text>
                                                <i class="material-icons">check_circle</i>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </div>
                        </slick>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>
        <!-- sticky bar -->
        <v-row class="px-0">
            <StickyBar
                    :isDisable="isDisable"
                    :goTo="'assicurati_axa'"
                    :goBack="'home_axa'"
                    :btn-label="'Prosegui'"
                    :level_2_number="'AXA_PerNoi'"
                    :click_name="'Prosegui_step1'"
                    :click_type="idSession"
            />
        </v-row>
    </v-container>
</template>

<script>
    import Slick from "vue-slick";
    import {mapState, mapGetters, mapActions} from "vuex";
    import Dialog from "@/components/partials/Dialog";
    import StickyBar from "@/components/base/axa/StickyBar";

    export default {
        name: "chiAmiBody",
        components: {
            Slick,
            Dialog,
            StickyBar
        },
        data() {
            return {
                carouselOptions: {
                    autoplay: false,
                    infinite: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 1904,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 1264,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                centerMode: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                },
                active_el: 0,
                oldValue: 0,
                isActive: false,
                isCompEnable: true,
                urlInsuredType: "api/insured_type",
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        mounted() {
            this.actionInsuredType();
            this.active_el = this.getInsType;
            this.actionIsDisable(true);
            if (this.getInsType) {
                this.actionPreviousInsType(this.getInsType);
                this.actionIsDisable(false);
            }
        },
        watch: {
            items() {
                if (this.$refs.carousel) {
                    this.$refs.carousel.destroy();
                    this.$nextTick(function () {
                        this.$refs.carousel.create(this.carouselOptions);
                    });
                }
            }
        },
        computed: {
            ...mapState({
                items: state => state.cards.insuredTypeItems,
                chiami: state => state.dialog.chiami
            }),
            ...mapGetters({
                isDisable: "stickyBar/getIsDisable",
                getInsType: "params/getInsType"
            })
        },
        methods: {
            ...mapActions({
                actionIsDisable: "stickyBar/actionIsDisable",
                actionInsuredType: "cards/actionInsuredType",
                actionInsType: "params/actionInsType",
                actionPreviousInsType: "params/actionPreviousInsType"
            }),

            selectedItem: function (el) {
                if (el === this.oldValue) {
                    this.active_el = 0;
                    this.actionIsDisable(true);
                } else {
                    this.active_el = el;
                    this.actionIsDisable(false);
                }
                this.oldValue = this.active_el;
                this.actionInsType(this.active_el);
            },
        }
    };
</script>
