<template>
    <v-row>
        <v-col cols="12" class="mb-6">
            <v-expansion-panels
                    v-model="panel"
                    multiple
            >
                <v-expansion-panel
                        v-for="item in items" :key="item.title"
                >
                    <v-expansion-panel-header>
                        {{ title }}
                        <template v-slot:actions>
                            <v-icon color="AXABlue">keyboard_arrow_down</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>

                        <v-row v-for="(row, index) in item.rows" :key="item.rows[index].title">
                            <v-col cols="12" class="text-center text-md-left">
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-avatar>
                                            <img :src="item.rows[index].image" alt="">
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <h4 class="title" v-html="item.rows[index].title"></h4>
                                        <div v-html="item.rows[index].text">

                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-expansion-panel-content>

                    <v-expansion-panel-header
                            v-show="showHeaderAccordion"
                    >
                        {{ title }}
                        <template v-slot:actions>
                            <v-icon color="AXABlue">keyboard_arrow_down</v-icon>
                        </template>
                    </v-expansion-panel-header>

                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
    import {mapState, mapGetters, mapMutations} from "vuex";

    export default {

        name: "PremioAccordionMPS",
        data() {
            return {
                showHeaderAccordion: false,
                title:''
            };
        },
        computed: {
            ...mapState({
                items: state => state.premio.items,
            }),
            ...mapGetters({
                'getPanelOpen': "params/getPanelOpen",
            }),
            panel: {
                set(value) {
                    if (!this.showHeaderAccordion) {
                        window.tc_events_global(this, 'xt_click', {
                            'level_2_number': 'AXA_PerNoi',
                            'click_name': 'Più_info',
                            'click_type': sessionStorage.getItem('sessionId')
                        });
                    }
                    this.setPanelOpen(value);
                    this.showHeaderAccordion = !this.showHeaderAccordion;
                    this.setTitle();
                },
                get() {
                    return this.$store.getters.getPanelOpen;
                }
            },
        },
        mounted(){
            this.title = this.items[0].title;
        },
        methods: {
            ...mapMutations({
                'setPanelOpen': "params/setPanelOpen",
            }),
            setTitle() {
                if (this.showHeaderAccordion) {
                    this.title = this.items[0].title_open;
                } else {
                    this.title = this.items[0].title;
                }
            }
        }
    };
</script>

