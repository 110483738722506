<template>
    <v-app>
        <Header/>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
        <FooterStep/>
    </v-app>
</template>

<script>
    import Header from "@/components/base/mps/Header";
    import FooterStep from "@/components/base/mps/Footer-stepbar";

    export default {
        name: "masterStepMPS",
        components: {
            Header,
            FooterStep,
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
    };
</script>
