<template>
    <v-app-bar flat height="70">
        <v-toolbar-title>
            <router-link to="/mps">
                <v-img src="@/assets/images/loghi.svg" width="204" height="51"/>
            </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <p class="headline mb-0 font-weight-bold AXABlue--text">Per Noi</p>
    </v-app-bar>
</template>

<script>
    export default {
        name: "headerMps",
    };
</script>