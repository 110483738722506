<template>
    <v-container fluid>
        <v-form ref="prospetto" v-model="valid" lazy-validation id="formSalva">
            <v-row>
                <v-col cols="12" class="text-left pb-0">
                    <v-row>
                        <v-container fluid class="pb-0">
                            <span class="pink title text-uppercase py-1 px-2">i tuoi dati</span>
                            <v-row>
                                <v-col cols="12">
                                    <p class="caption text-lowercase">*campi obbligatori</p>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" md="6" id="name">
                                            <v-text-field v-model="name" label="Nome*" :rules="inputRules"
                                                          @click="checkPrivacy" ref="field1"
                                                          v-scroll-to="'#name'"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="lastName">
                                            <v-text-field v-model="lastName" label="Cognome*" @click="checkPrivacy"
                                                          ref="field2"
                                                          :rules="inputRules"
                                                          v-scroll-to="'#lastName'"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="telephone">
                                            <v-text-field v-model="telephone" label="Telefono*" @click="checkPrivacy"
                                                          ref="field4"
                                                          v-scroll-to="'#telephone'"
                                                          type="tel"
                                                          :rules="telephoneRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="email">
                                            <v-text-field v-model="email" label="E-mail*" @click="checkPrivacy"
                                                          ref="field3"
                                                          v-scroll-to="'#email'"
                                                          :rules="emailRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="code">
                                            <v-text-field v-model="cap" label="Cap*" @click="checkPrivacy"
                                                          ref="field5"
                                                          v-scroll-to="'#cap'"
                                                          :rules="capRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pt-5">
                                            <a href="/docs/axa/privacy_2023.pdf" target="_blank" class="privacy__link pt-5">Informativa privacy</a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <!-- privacy -->
        <v-dialog v-model="privacyDialog" scrollable persistent max-width="960" class="dialog">
            <v-card tile class="py-5">
                <v-card-title class="px-3 dialog__card__title py-3">
                    <v-row>
                        <v-col cols="12" class="text-center py-0 py-md-3">
                            <h2 class="mb-3 mb-md-6 display-2 mt-3">Informativa privacy</h2>
                            <h3>Informativa privacy</h3>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="px-3 dialog__card__text px-8 py-0">
                    <v-row>
                        <v-col cols="12" class="text-md-left text_container">
                            La informiamo che i suoi dati personali sono trattati da AXA Assicurazioni S.p.A., titolare del trattamento, per gestire la sua richiesta ed i servizi assicurativi connessi. I dati raccolti potranno, inoltre, essere trattati per attività statistiche interne connesse alla fornitura del servizio erogato (finalità assicurativa).<br>
                            Per esercitare i Suoi diritti (accedere ai suoi dati personali, farli aggiornare, integrare, rettificare o cancellare, di chiederne la limitazione e di opporsi al loro trattamento, richiedere la portabilità dei dati, revocare i consensi espressi e, per il processo di decisione automatizzata, ottenere l’intervento umano da parte del titolare del trattamento, esprimere la propria opinione e contestare la decisione, ottenere informazioni sui trasferimenti verso paesi che non fanno parte dell’Unione Europea e sul legittimo interesse di AXA, può scrivere a AXA Assicurazioni S.p.A. – c.a. Data Protection Officer – Corso Como 17 - 20154 Milano (MI) - e-mail: <a href="mailto:privacy@axa.it">privacy@axa.it</a>. Per maggiori informazioni, può leggere l’<a href="/docs/axa/privacy_2023.pdf" target="_blank" class="privacy__link">informativa estesa</a>. Inoltre, Lei potrà proporre reclamo all'Autorità di controllo in materia di protezione dei dati personali, in Italia il Garante per la protezione dei dati personali: Piazza Venezia n. 11 - 00187 Roma (+39) 06.696771; <a href="mailto:protocollo@pec.gpdp.it">protocollo@pec.gpdp.it</a><br>
                            <br>
                            <span class="red--text">Attenzione: il servizio è disponibile solo per i maggiorenni</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title class="mt-5 v-card__footer py-0">
                    <v-col cols="12" class="text-center">
                        <div class="dialog__card__text text_container">
                            Per visualizzare l’informativa completa, <a href="/docs/axa/privacy_2023.pdf" target="_blank" class="privacy__link">Clicca qui</a>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center pb-0">
                        <v-btn x-large tile elevation="0" @click="privacyDialog = false, privacy=true"
                               class="pinkDark white--text">
                            Ho capito
                        </v-btn>
                    </v-col>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import DialogScrollable from "@/components/partials/DialogScrollable";
    import Tooltip from "@/components/partials/Tooltip";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

    export default {
        name: "FormSalva",
        components: {
            Tooltip,
            DialogScrollable
        },
        computed: {
            ...mapGetters({
                'getInsuredData': 'params/getInsuredData',
            }),
        },
        watch: {},
        data: () => ({
            prospetto: false,
            privacy: false,
            promozioni: [{
                text: "I dati sono trattati per fornire offerte, promozioni e soluzioni innovative e personalizzate, migliorando il livello di protezione. Il trattamento dei dati è facoltativo e possibile solo con il consenso dell’interessato liberamente revocabile.",
            }],
            valid: false,
            inputRules: [
                v => !!v || 'Campo obbligatorio',
            ],
            name: '',
            lastName: '',
            telephone: '',
            telephoneRules: [
                v => !!v || 'Campo obbligatorio',
                v => /(^\+\d{11,12}$)|(^00\d{11,12}$)|(^\d{9,10}$)/.test(v) || 'Il formato del numero di telefono non è corretto',
                //v => /^([a-zA-Z0-9_-]){3,5}$/g.test(v) || 'Il formato del numero di telefono non è corretto',
            ],
            cap: '',
            capRules: [
                v => !!v || 'Campo obbligatorio',
                v => /^\d{5}$/.test(v) || 'Il cap non è corretto',
            ],
            email: '',
            emailRules: [
                v => !!v || 'Campo obbligatorio',
                v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) || 'E-mail non valida',
            ],
            consenso: 'no',
            // consensoRules: [
            //     v => !!v || 'Campo obbligatorio',
            // ],
            cont: 1,
            privacyDialog: false,
            idSession: sessionStorage.getItem('sessionId'),
        }),
        mounted() {
            this.name = this.getInsuredData.name;
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionSaveDataAAI': 'params/actionSaveDataAAI',
            }),
            checkPrivacy($event) {
                if (!this.privacy) {
                    //TODO: Fix orribile, da sistemare
                    this.$refs.field1.blur();
                    this.$refs.field2.blur();
                    this.$refs.field3.blur();
                    this.$refs.field4.blur();
                    if ($event) {
                        this.$refs.prospetto.resetValidation();
                    }
                    this.privacyDialog = true;
                }
            },
        },
        updated() {
            if (this.consenso != '')
                this.checkPrivacy();

            if (this.name != '' && this.lastName != '' && this.telephone != '' && this.email != '' && this.cap != '' && this.consenso != '' && this.valid) {
                let data = {
                    name: this.name,
                    lastName: this.lastName,
                    telephone: this.telephone,
                    email: this.email,
                    cap: this.cap,
                    consenso: this.consenso,
                };

                this.actionSaveDataAAI(data);
                this.actionIsDisable(false);
                this.actionIsFormValidate(true);
            } else {
                this.actionIsDisable(true);
            }
        },
    };
</script>
<style scoped>
    .v-input--selection-controls {
        margin-top: 0;
    }
</style>

