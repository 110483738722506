export default ({
    state: {
        items: [
            {
                title: 'Una protezione per due',
                description: "<p>Per Noi è la polizza vita che <strong>protegge due persone di età compresa tra i 18 e i 60</strong> anni, in un’<strong>unica soluzione</strong>, qualunque sia il legame che le unisce.</p>" +
                    "<p>Coppie nella vita, coppie in affari, amici di una vita, fratelli e sorelle.</p>" +
                    "<p><strong>Per Noi è per tutti.</strong></p>",
                image: require('@/assets/images/home/infografica/infografica-img-01.png'),
                box: false,
            },
            {
                title: 'La certezza di un futuro al sicuro per te e per chi ami',
                description: "<p>Con Per Noi proteggi te stesso e le persone importanti della tua vita dai grandi imprevisti che possono accadere, <strong>assicurando un capitale</strong> che permetterà di:</p>",
                image: require('@/assets/images/home/infografica/infografica-img-02.png'),
                box: true,
                boxes: [
                    {
                        number: '1',
                        text: 'Affrontare con serenità i momenti difficili della vita, in caso di <strong>malattia grave</strong> di uno degli assicurati'
                    },
                    {
                        number: '2',
                        text: 'Mantenere il proprio tenore di vita, in caso di <strong>perdita di autosufficienza</strong> di uno dei due assicurati'
                    },
                    {
                        number: '3',
                        text: 'Garantire, ai beneficiari che desideri, un sostegno concreto in caso di <strong>prematura scomparsa</strong> di una delle due persone assicurate'
                    }
                ]
            },
            {
                title: 'Un solo elemento da scegliere: il capitale da assicurare',
                description: "<p>Da un <strong>minimo di € 25.000</strong> a un <strong>massimo di € 125.000</strong>.<br />Il capitale viene erogato al primo evento negativo che colpisce uno dei due assicurati e in caso di evento simultaneo il capitale riconosciuto <strong>raddoppia</strong> per una protezione davvero concreta.</p>" +
                    "<p>Inoltre, sei libero di nominare chi desideri come beneficiario del capitale, senza vincoli: i tuoi <strong>figli</strong>, i tuoi <strong>fratelli</strong>, i tuoi <strong>genitori</strong>, la <strong>tua azienda</strong> o <strong>l’amico</strong> di una vita.</p>",
                image: require('@/assets/images/home/infografica/infografica-img-03.png'),
                box: false,
            },
            {
                title: 'Assicuri due persone a partire da soli 12€ al mese',
                description: "<p>La libertà di scegliere come pagare, la certezza del <strong>prezzo bloccato</strong>. Per Noi è semplice:</p>",
                image: require('@/assets/images/home/infografica/infografica-img-04.png'),
                box: true,
                boxes: [
                    {
                        number: '1',
                        text: 'Hai la garanzia del <strong>prezzo bloccato</strong>, per tutta la durata della polizza: 10 anni'
                    },
                    {
                        number: '2',
                        text: 'Sei libero di scegliere se pagare il premio <strong>ogni mese</strong> o <strong>una sola volta all’anno</strong>'
                    },
                    {
                        number: '3',
                        text: 'Puoi disdire il contratto in qualsiasi momento, <strong>senza alcuna penale</strong>'
                    }
                ]
            },
        ],
    },
})
