<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <v-dialog v-model="privacyDialog" max-width="960" scrollable persistent v-for="item in items"
                      :key="item.title">
                <template v-slot:activator="{ on }">
                    <div v-on="on" @click="hitTrack(level_2_number,click_name,click_type)">
                        <v-btn text class="AXABlue--text text-none px-0" :ripple="false"><i class="material-icons">{{ item.link }}</i></v-btn>
                    </div>
                </template>
                <v-card tile>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                class="AXABlue--text text-right"
                                :elevation="0"
                                fab
                                small
                                text
                                @click="privacyDialog = false">
                            <v-icon dark>clear</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-card-title class="py-0 shadow-bottom">
                        <v-row>
                            <v-col cols="12" class="text-center py-0 py-md-3">
                                <h2 class="mb-3 mb-md-6 display-1">{{ item.title }}</h2>
                                <p><strong>{{ item.subtitle }}</strong></p>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <div class="text-left mt-4" v-html="item.text"></div>
                    </v-card-text>
                    <!-- bottone -->
                    <v-card-actions class="shadow-top">
                        <v-container class="py-0">
                            <v-row>
                                <v-col cols="12 text-center py-0">
                                    <p class="mb-0">Per visualizzare l’informativa completa, <a :href="item.privacyLink" target="_blank">Clicca qui</a></p>
                                </v-col>
                                <v-col cols="12 text-center">
                                    <v-btn x-large tile elevation="0" @click="privacyDialog = false, privacy=true"
                                           class="pinkDark white--text"
                                           data-aos="fade-zoom-in"
                                           data-aos-easing="ease"
                                           data-aos-duration="500"
                                           data-aos-delay="800">
                                        Ho capito
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "privacyDialog",
        props: ['items', 'boxes', 'level_2_number', 'click_name', 'click_type'],
        data() {
            return {
                privacyDialog: false,
                privacy: false,
            };
        },
        methods: {
            hitTrack(level_2_number, click_name, click_type) {
                tc_events_global(this, 'xt_click', {
                    'level_2_number': level_2_number,
                    'click_name': click_name,
                    'click_type': click_type
                });
            }
        }
    };
</script>
