const state = {
    //idSession : TokenService.getSessionId(),
    lead : null,
    insTypeSelect : null,
    previousInsTypeSelect: null,
    quotation: {},
    insType : {},
    projectsSelected: [],
    listInsuredData: {},
    beneficiariesData: '',
    appointmentData: {},
    appointmentDataAAI: {},
    saveData: {},
    saveDataAAI: {},
    idCapitale : null,
    previousIdCapitale: null,
    contactMail: '',
    customerType: '',
    isParent: false,
    identifier: 0,
    panelOpen: [],
};

const getters = {
    getLead : state => {
        return state.lead;
    },
    getQuotationId : state => {
        return state.quotation.identifier;
    },
    getQuotation : state => {
        return state.quotation;
    },
    getInsType : state => {
        return state.insTypeSelect;
    },
    getPreviousInsType: state => {
        return state.previousInsTypeSelect;
    },
    getInsTypeData : state => {
        return state.insType;
    },
    getSelectedProjects: state => {
        return state.projectsSelected;
    },
    getInsuredData: state => {
        return state.listInsuredData;
    },
    getBeneficiariesData: state => {
        return state.beneficiariesData;
    },
    getAppointmentData: state => {
        return state.appointmentData;
    },
    getAppointmentDataAAI: state => {
        return state.appointmentDataAAI;
    },
    getSaveData: state => {
        return state.saveData;
    },
    getSaveDataAAI: state => {
        return state.saveDataAAI;
    },
    getIdCapitale: state => {
        return state.idCapitale;
    },
    getPreviousIdCapitale: state => {
        return state.previousIdCapitale;
    },
    getContactMail: state => {
        return state.contactMail;
    },
    getCustomerType: state => {
        return state.customerType;
    },
    getCustomerTypeTrk: state => {
        switch (state.customerType){
            case '':
                return '';
            case 'mps':
                return 'Mps';
            case 'axa':
                return 'Axa';
            case 'no_cliente':
                return 'No';
            default:
                return '';
        }
    },
    getIsParent: state => {
        return state.isParent;
    },
    getIdentifier: state => {
        return state.identifier;
    },
    getPanelOpen : state => {
        return state.panelOpen;
    },
};

const actions = {
    actionInsType : (context, value) => {
        context.commit('setInsType', value);
    },
    actionPreviousInsType : (context, value) => {
        context.commit('setPreviousInsTypeSelect', value);
    },
    actionQuotationParams : (context, value) => {
        context.commit('setQuotation', value);
    },
    actionInsTypeData : ({commit, getters}) => {
        let insured_type_api = process.env.VUE_APP_INSURED_TYPE + '/' + getters.getInsType;
        window.axios.get(insured_type_api, {})
        .then(resp => {
            let insTypeData = resp.data.data;
            commit('setInsTypeData', insTypeData);
        });

    },
    actionSelectedProjects: (context, value) =>{
        context.commit('setSelectedProjects', value);
        //console.log('setSelectedProjects', value);
    },
    //actionInsuredData
    actionInsuredData: (context, data) =>{
        context.commit('setInsuredData', data);
    },
    actionBeneficiariesData: (context, data) =>{
        context.commit('setBeneficiariesData', data);
    },
    actionAppointmentData: (context, data) =>{
        context.commit('setAppointmentData', data);
    },
    actionAppointmentDataAAI: (context, data) =>{
        context.commit('setAppointmentDataAAI', data);
    },
    actionSaveData: (context, data) =>{
        context.commit('setSaveData', data);
    },
    actionSaveDataAAI: (context, data) =>{
        context.commit('setSaveDataAAI', data);
    },
    actionIdCapitale: (context, value) =>{
        context.commit('setIdCapitale', value);
    },
    actionClearQuotation: (context, value) =>{
        context.commit('setClearQuotationData', value);
    },
    actionContactMail: (context, value) =>{
        context.commit('setContactMail', value);
    },
    actionCustomerType: (context, data)  => {
        context.commit('setCustomerType', data);
    }

};

const mutations = {
    setInsType : (state, value) => {
        state.previousInsTypeSelect = state.insTypeSelect;
        state.insTypeSelect = value;
    },
    setPreviousInsTypeSelect : (state, value) => {
        state.previousInsTypeSelect = value;
    },
    setQuotation : (state, value) => {
        state.quotation = value;
    },
    setInsTypeData : (state, value) => {
        state.insType = value;
    },
    setSelectedProjects : (state, value) => {
        state.projectsSelected = value;
    },
    setInsuredData : (state, data) => {
        state.listInsuredData = data;
        //console.log(state.listInsuredData);
    },
    setBeneficiariesData : (state, data) => {
        state.beneficiariesData = data;
        //console.log(state.beneficiariesData);
    },
    setAppointmentData : (state, data) => {
        state.appointmentData = data;
    },
    setAppointmentDataAAI : (state, data) => {
        state.appointmentDataAAI = data;
    },
    setSaveData: (state, data) => {
        state.saveData = data;
    },
    setSaveDataAAI: (state, data) => {
        state.saveDataAAI = data;
    },
    setIdCapitale : (state, value) => {
        state.previousIdCapitale = state.idCapitale;
        state.idCapitale = value;
    },
    setClearQuotationData : (state, value) => {
        state.projectsSelected = [];
        state.listInsuredData = {};
        state.beneficiariesData = '';
        state.appointmentData = {};
        state.appointmentDataAAI = {};
        state.saveData = {};
        state.saveDataAAI = {};
        state.idCapitale = null;
        state.previousIdCapitale = null;
    },
    setContactMail : (state, value) => {
        state.contactMail = value;
    },
    setCustomerType : (state, value) => {
        state.customerType = value;
    },
    setIsParent: (state, value) => {
        state.isParent = value;
    },
    setIndetifier: (state, value) => {
        state.identifier = value;
    },
    setPanelOpen : (state, value) => {
        state.panelOpen = value;
    },
};


export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}

