<template>
    <v-container fluid>
        <v-form ref="prospetto" v-model="valid" lazy-validation id="formSalva">
            <v-row>
                <v-col cols="12" class="text-left pb-0">
                    <v-row>
                        <v-container fluid class="pb-0">
                            <span class="pink title text-uppercase py-1 px-2">i tuoi dati</span>
                            <v-row>
                                <v-col cols="12">
                                    <p class="caption text-lowercase">*campi obbligatori</p>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" md="6" id="name">
                                            <v-text-field v-model="name" label="Nome*" :rules="inputRules"
                                                          ref="field1"
                                                          @click="checkPrivacy"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="lastName">
                                            <v-text-field v-model="lastName" label="Cognome*"
                                                          ref="field2"
                                                          @click="checkPrivacy"
                                                          :rules="inputRules"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="email">
                                            <v-text-field v-model="email" label="E-mail*"
                                                          @click="checkPrivacy"
                                                          ref="field3"
                                                          :rules="emailRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="date">
                                            <v-text-field
                                                    maxlength="10"
                                                    @keyup="date=formatDate(date)"
                                                    color="AXABlue"
                                                    label="Data di nascita*"
                                                    placeholder="GG/MM/AAAA"
                                                    :rules="dateRules"
                                                    v-model="date"
                                                    type="tel"
                                                    @click="checkPrivacy"
                                                    ref="field4"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>`
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <!-- privacy -->
        <v-dialog v-model="privacyDialog" scrollable persistent max-width="960" class="dialog">
            <v-card tile class="py-5">
                <v-card-title class="px-3 dialog__card__title py-3">
                    <v-row>
                        <v-col cols="12" class="text-center py-0 py-md-3">
                            <h2 class="mb-3 mb-md-6 display-2 mt-3">Informativa privacy</h2>
                            <h3>Informativa privacy</h3>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="px-3 dialog__card__text px-8 py-0">
                    <v-row>
                        <v-col cols="12" class="text-md-left text_container">
                            <strong>Gentile Cliente, AXA MPS Assicurazioni Vita S.p.A. e Banca Monte dei Paschi di
                                Siena S.p.A. desiderano fornirLe le
                                seguenti informazioni in merito al trattamento dei Suoi dati personali raccolti per
                                effettuare il preventivo della polizza.</strong><br/>
                            <br/>
                            <br/>
                            <strong>Chi decide perché e come trattare i dati?</strong><br/>
                            AXA MPS Assicurazioni Vita S.p.A. (di seguito “AXA MPS”), in qualità di Titolare
                            autonomo del trattamento dei dati.<br/>
                            Si precisa che, per l’erogazione dei servizi richiesti, la Banca Monte dei Paschi di
                            Siena S.p.A. (di seguito la “Banca”) con sede a Siena in piazza Salimbeni n. 3, è
                            intermediario assicurativo dei prodotti di AXA MPS e opera anch’esso come
                            Titolare autonomo del trattamento per attività di identificazione e successivo
                            contatto, se richiesto da Lei e di identificazione (ovvero verificare se è già
                            cliente).<br/>
                            <br/>
                            <strong>
                                Per quali scopi e su quale
                                base i dati sono trattati?<br/>
                                Cosa comporta il rifiuto
                                di fornire i dati?
                            </strong><br/>
                            a) Finalità assicurativa<br/>
                            I dati anagrafici e di contatto sono trattati per gestire la Sua richiesta di procedere
                            ad una quotazione e per attività statistiche ad essa correlate.<br/>
                            Saranno comunicati alla Banca solamente i suoi dati anagrafici, il recapito telefonico e
                            il codice fiscale per la verifica della qualità di cliente della Banca.
                            Il trattamento dei dati è necessario per dare
                            esecuzione alle misure precontrattuali da Lei richieste, per adempiere obblighi di
                            legge, per legittimo interesse connesso all’attività assicurativa (attività statistica).
                            Senza i dati non potranno essere forniti i servizi assicurativi.<br/><br/>


                            b) Finalità di contatto da parte della Banca<br/>
                            Qualora Lei richieda di essere contattato per fissare un appuntamento in una filiale
                            della Banca, la Compagnia provvede a comunicare a quest&#39;ultima i seguenti dati
                            personali a Lei riferiti: nome e cognome, codice fiscale (al fine di verificare se è già
                            nostro cliente), CAP (per cercare la filiale a Lei più vicina nel caso non sia già
                            nostro
                            cliente) e telefono. La Banca non acquisisce alcuna informazione inerente al
                            preventivo della polizza che ha effettuato.<br/>
                            Il trattamento dei suddetti dati, da parte della Banca, risulta necessario per dar
                            seguito alla Sua richiesta di essere contattato; al riguardo Le precisiamo che per
                            tale finalità non è richiesto il Suo preventivo consenso dal momento che la base
                            giuridica che ne legittima il trattamento è la necessità di dar corso a Sue
                            specifiche richieste. In tal caso il conferimento dei dati non è obbligatorio ma un
                            eventuale rifiuto a fornirli, anche parzialmente, comporterebbe per la Banca
                            l’impossibilità di fornire il servizio richiesto.<br/>
                            <br/>
                            <strong>
                                A chi sono comunicati
                                i dati?
                            </strong><br/>
                            I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra
                            indicati:<br/>
                            - Dipendenti e collaboratori;<br/>
                            - Società terze che trattano i dati per conto del Titolare; in particolare, la Banca nel
                            caso in cui Lei chieda di essere contattato per fissare un appuntamento in una
                            filiale;<br/>
                            - Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella
                            gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori,
                            coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti
                            pubblici o associazioni di categoria;<br/>
                            - altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di
                            informazione commerciale per rischi finanziari<br/>
                            In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie
                            previste dalla vigente normativa.<br/>
                            <i>I dati non sono ceduti a terzi, né diffusi.</i><br/>
                            <br/>
                            <strong>
                                Quali sono i diritti dell’interessato
                                e come esercitarli?
                            </strong><br/>
                            Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il
                            blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la
                            portabilità dei dati e far valere il diritto all’oblio, l’interessato può scrivere
                            a:<br/>
                            AXA MPS Assicurazioni Vita S.p.A. c.a. Data Protection Officer - Corso Como 17 – 20154
                            Milano (MI) - e-mail: <a href="mailto:privacy@axa.it">privacy@axa.it</a>.<br/>
                            Banca Monte dei paschi di Siena S.p.A. - Staff DPO e Conformità Privacy, Via A. Moro n.
                            11/13 - 53100 Siena (fax + 39 0577 296520; e-mail: <a href="mailto:privacy@mps.it">privacy@mps.it</a>).<br/>
                            Inoltre, potrà proporre reclamo al Garante Privacy (<a
                                href="https://www.garanteprivacy.it/"
                                target="_blank">https://www.garanteprivacy.it/</a>)<br/>
                            <br/>
                            <strong>
                                Da dove provengono
                                i dati trattati?
                            </strong><br/>
                            I dati sono raccolti da AXA MPS direttamente presso l’interessato o da terzi, anche
                            mediante la consultazione di banche dati.<br/>
                            La Banca acquisisce dalla Compagnia esclusivamente i dati personali sopra indicati
                            necessari per effettuare il contatto.<br/>
                            <br/>
                            <strong>
                                Per quanto tempo i dati vengono conservati?
                            </strong><br/>
                            I dati vengono conservati da AXA MPS per tutto il tempo necessario alla gestione del
                            rapporto con l’interessato: decorsi 10 anni dalla cessazione del rapporto con il cliente
                            si procede alla cancellazione dei dati.<br/>
                            La Banca conserva i dati per il tempo necessario ad effettuare il contatto che viene
                            effettuato entro 60 giorni trascorso tale termine i dati sono cancellati.<br/>
                            <br/>
                            <strong>
                                Informazioni aggiuntive
                            </strong><br/>
                            L’informativa completa è disponibile sul sito <a href="https://www.axa-mps.it/"
                                                                             target="_blank">www.axa-mps.it</a>.
                            Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione
                            Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.<br/>
                            <br/>
                            <strong>
                                Responsabili della Protezione dei Dati
                            </strong><br/>
                            Banca Monte dei Paschi di Siena S.p.A e da AXA MPS Assicurazioni Danni S.p.A, hanno
                            istituito la figura del Responsabile della Protezione dei Dati (o Data Protection
                            Officer – DPO) prevista dalla normativa, affidandogli compiti di informazione e
                            consulenza per il titolare del trattamento in merito agli obblighi in materia di
                            protezione dei dati personali e di verifica dell’attuazione ed applicazione della
                            normativa.<br/>
                            I DPO possono essere contattati dagli interessati per tutte le questioni relative al
                            trattamento dei propri dati personali e per l’esercizio dei diritti previsti dal GDPR
                            tramite i seguenti recapiti:<br/>
                            - per AXA MPS Assicurazioni Danni S.p.A. – c.a. Data Protection Officer – Corso Como 17
                            – 20154 Milano – <a href="mailto:privacy@axa.it">privacy@axa.it</a><br/>
                            - per Banca Monte dei Paschi di Siena S.p.A. PEC: <a
                                href="mailto:responsabileprotezionedeidati@postacert.gruppo.mps.it">responsabileprotezionedeidati@postacert.gruppo.mps.it</a>;
                            e mail ordinaria <a href="mailto:responsabileprotezionedeidati@mps.it">responsabileprotezionedeidati@mps.it</a><br/>
                            <br/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title class="mt-5 v-card__footer py-0">
                    <v-col cols="12" class="text-center">
                        <div class="dialog__card__text text_container">
                            Per visualizzare l’informativa completa, <a href="https://www.axa-mps.it/privacy" target="_blank" class="privacy__link">Clicca qui</a>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center pb-0">
                        <v-btn x-large tile elevation="0" @click="privacyDialog = false, privacy=true"
                               class="pinkDark white--text">
                            HO CAPITO
                        </v-btn>
                    </v-col>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import DialogScrollable from "@/components/partials/DialogScrollable";
    import Tooltip from "@/components/partials/Tooltip";
    import * as moment from 'moment';
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

    export default {
        name: "FormSalva",
        components: {
            Tooltip,
            DialogScrollable
        },
        computed: {
            ...mapGetters({
                'getInsuredData': 'params/getInsuredData',
            }),
        },
        watch: {},
        data: () => ({
            prospetto: false,
            privacy: false,
            valid: false,
            inputRules: [
                v => !!v || 'Campo obbligatorio',
            ],
            name: '',
            lastName: '',
            telephone: '',
            date: '',
            dateRules: [
                val => moment(val, "DD/MM/YYYY") >= moment().subtract(60, 'years') && moment(val, "DD/MM/YYYY") <= moment().subtract(18, 'years') || `L'età deve essere compresa tra 18 e 60 anni`,
                val => /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/i.test(val) || 'La data deve avere formato gg/mm/aaaa',
            ],
            email: '',
            emailRules: [
                v => !!v || 'Campo obbligatorio',
                v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) || 'E-mail non valida',
            ],
            privacyDialog: false,
            idSession: sessionStorage.getItem('sessionId'),
        }),
        mounted() {
            this.name = this.getInsuredData.name;
            this.date = this.getInsuredData.date;
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionSaveData': 'params/actionSaveData',
            }),
            checkPrivacy($event) {
                if (!this.privacy) {
                    //TODO: Fix orribile, da sistemare
                    this.$refs.field1.blur();
                    this.$refs.field2.blur();
                    this.$refs.field3.blur();
                    this.$refs.field4.blur();
                    // this.$refs.field5.blur();
                    if ($event) {
                        this.$refs.prospetto.resetValidation();
                    }
                    this.prospetto = false;
                    this.privacyDialog = true;
                }
            },
            formatDate: function (value) {
                value = value.replace(/\D/g, "")
                    .split(/(..)/)
                    .filter(s => s.length > 0)
                    .reduce((t, v, i, a) => t + v + (i > 1 || i == a.length - 1 ? '' : '/'), '');
                return value;
            }
        },
        updated() {
            if (this.name != '' && this.lastName != '' && this.email != '' && this.date != '' && this.valid) {
                let data = {
                    name: this.name,
                    lastName: this.lastName,
                    email: this.email,
                    date: this.date,
                    priv: 'si',
                };

                this.actionSaveData(data);
                this.actionIsDisable(false);
                this.actionIsFormValidate(true);
            } else {
                this.actionIsDisable(true);
            }
        },
    };
</script>
