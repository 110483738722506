<template>
    <v-container fluid class="pt-0">
        <v-form ref="form" v-model="valid" lazy-validation id="form">
            <v-row>
                <v-col cols="12 text-left">
                    <p class="mb-0">* Campi obbligatori</p>
                </v-col>
                <v-col cols="12" sm="6" class="text-left">
                    <v-row>
                        <v-col cols="12" id="name">
                            <span class="pink title text-uppercase py-1 px-2">Tu sei</span>
                            <v-text-field v-model="name" :rules="rules.name" label="Nome*"
                                          color="AXABlue"
                                          v-scroll-to="'#name'"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" id="date">
                            <v-text-field
                                    maxlength="10"
                                    @keyup="date=formatDate(date)"
                                    color="AXABlue"
                                    label="Data di nascita*"
                                    placeholder="GG/MM/AAAA"
                                    :rules="rules.date"
                                    v-model="date"
                                    type="tel"
                                    v-scroll-to="'#date'"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-left">
                    <v-row>
                        <v-col cols="12" id="namePartner">
                            <span class="pink title text-uppercase py-1 px-2">{{ getInsTypeData.labelPartner }}</span>
                            <v-text-field v-model="namePartner" :rules="rules.name" label="Nome*"
                                          color="AXABlue"
                                          v-scroll-to="'#namePartner'"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" id="datePartner">
                            <v-text-field
                                    maxlength="10"
                                    @keyup="datePartner=formatDate(datePartner)"
                                    color="AXABlue"
                                    label="Data di nascita*"
                                    placeholder="GG/MM/AAAA"
                                    :rules="rules.date"
                                    v-model="datePartner"
                                    type="tel"
                                    v-scroll-to="'#datePartner'"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- checkbox and privacy -->
            <v-row>
                <!-- checkbox -->
                <!--                <v-col cols="12" class="text-left py-0">
                                    <p class="radio-label mb-0 mr-4 d-inline-block">Acconsento al trattamento dei dati per finalità
                                        commerciali</p>
                                    <v-radio-group v-model="commerciali" required row class="justify-center d-inline-block mt-0 pt-0">
                                        <v-radio color="AXABlue" label="Si" value="radio-1"></v-radio>
                                        <v-radio color="AXABlue" label="No" value="radio-2"></v-radio>
                                    </v-radio-group>
                                </v-col>-->
                <!-- privacy -->
                <!--                <v-col cols="12" class="text-left py-0">
                                    <DialogScrollable :items="privacy"
                                                      :level_2_number="'AXA_PerNoi'"
                                                      :click_name="'Più_info'"
                                                      :click_type="idSession"/>
                                </v-col>-->
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import DialogScrollable from "@/components/partials/DialogScrollable";
    import {mapActions, mapGetters} from "vuex";
    import * as moment from 'moment';

    export default {
        name: "Form",
        components: {
            DialogScrollable,
        },
        data: vm => ({
            valid: false,
            name: '',
            date: '',
            rules: {
                date: [
                    val => moment(val, "DD/MM/YYYY") >= moment().subtract(60, 'years') && moment(val, "DD/MM/YYYY") <= moment().subtract(18, 'years') || `L'età deve essere compresa tra 18 e 60 anni`,
                    val => /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/i.test(val) || 'La data deve avere formato gg/mm/aaaa',
                ],
                name: [val => (val || '').length > 0 || 'Campo obbligatorio'],
            },
            namePartner: '',
            datePartner: '',
            //commerciali: '',
            cont: 1,
            formId: 'personForm',
            idSession: sessionStorage.getItem('sessionId'),
        }),
        computed: {
            /*            ...mapState({
                            privacy: state => state.dialog.privacy
                        }),*/
            ...mapGetters({
                'getInsuredData': 'params/getInsuredData',
                'getInsTypeData' : 'params/getInsTypeData'
            }),
        },
        mounted() {
            this.actionInsTypeData();
            //console.log(this.getInsuredData);
            if (this.getInsuredData.name) {
                this.name = this.getInsuredData.name;
                this.date = this.getInsuredData.date;
                this.namePartner = this.getInsuredData.namePartner;
                this.datePartner = this.getInsuredData.datePartner;
                //this.commerciali = this.getInsuredData.commerciali;
            }
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionInsTypeData': 'params/actionInsTypeData',
                'actionInsuredData': 'params/actionInsuredData'
            }),
            formatDate: function (value) {
                value = value.replace(/\D/g, "")
                    .split(/(..)/)
                    .filter(s => s.length > 0)
                    .reduce((t, v, i, a) => t + v + (i > 1 || i == a.length - 1 ? '' : '/'), '');
                return value;
            }
        },
        updated() {
            let data = {};
            data.name = this.name;
            data.date = this.date;
            data.namePartner = this.namePartner;
            data.datePartner = this.datePartner;
            //data.commerciali = this.commerciali;

            this.actionInsuredData(data);
            if (this.name != '' && this.date != '' && this.namePartner != '' && this.datePartner != '' && this.valid) {
                this.actionIsDisable(false);
                this.actionIsFormValidate(true);
            } else {
                this.actionIsDisable(true);
                this.actionIsFormValidate(false);
            }
        },
    };
</script>
