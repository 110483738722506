<template>
    <v-container fluid>
        <v-form ref="appuntamento" v-model="valid" lazy-validation id="formAppuntamento">
            <v-row>
                <v-col cols="12" class="text-left pb-0">
                    <v-row>
                        <v-container fluid class="pb-0">
                            <span class="pink title text-uppercase py-1 px-2">i tuoi dati</span>
                            <v-row>
                                <v-col cols="12">
                                    <p class="caption text-lowercase">*campi obbligatori</p>
                                </v-col>
                                <v-col cols="12">
                                    <input type="hidden" v-model="consenso">
                                    <v-row>
                                        <v-col cols="12" md="6" id="name">
                                            <v-text-field v-model="name" label="Nome*" :rules="inputRules"
                                                          @click="checkPrivacy" ref="field1"
                                                          v-scroll-to="'#name'"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="lastName">
                                            <v-text-field v-model="lastName" label="Cognome*" @click="checkPrivacy"
                                                          ref="field2"
                                                          :rules="inputRules"
                                                          v-scroll-to="'#lastName'"
                                                          required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="telephone">
                                            <v-text-field v-model="telephone" label="Telefono*" @click="checkPrivacy"
                                                          ref="field3"
                                                          v-scroll-to="'#telephone'"
                                                          type="tel"
                                                          :rules="telephoneRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="email">
                                            <v-text-field v-model="email" label="E-mail*" @click="checkPrivacy"
                                                          ref="field4"
                                                          v-scroll-to="'#email'"
                                                          :rules="emailRules" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" id="code">
                                            <v-text-field v-model="code" label="Codice Fiscale*" @click="checkPrivacy"
                                                          ref="field5"
                                                          v-scroll-to="'#code'"
                                                          :rules="codeRules" required>
                                            </v-text-field>
                                            <CodiceFiscale @update="codiceFisc"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <!-- privacy -->
        <v-dialog v-model="privacyDialog" scrollable persistent max-width="960" class="dialog">
            <v-card tile class="py-5">
                <v-card-title class="px-3 dialog__card__title py-3">
                    <v-row>
                        <v-col cols="12" class="text-center py-0 py-md-3">
                            <h2 class="mb-3 mb-md-6 display-2 mt-3">Informativa privacy</h2>
                            <h3>Informativa privacy</h3>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="px-3 dialog__card__text px-8 py-0">
                    <v-row>
                        <v-col cols="12" class="text-md-left text_container">
                            <strong>Gentile Cliente, AXA MPS Assicurazioni Vita S.p.A. e Banca Monte dei Paschi
                                di Siena S.p.A. desiderano fornirLe le
                                seguenti informazioni in merito al trattamento dei Suoi dati personali raccolti
                                per effettuare il preventivo della polizza.</strong><br/>
                            <br/>
                            <br/>
                            <strong>Chi decide perché e come trattare i dati?</strong><br/>
                            AXA MPS Assicurazioni Vita S.p.A. (di seguito “AXA MPS”), in qualità di Titolare
                            autonomo del trattamento dei dati.<br/>
                            Si precisa che, per l’erogazione dei servizi richiesti, la Banca Monte dei Paschi di
                            Siena S.p.A. (di seguito la “Banca”) con sede a Siena in piazza Salimbeni n. 3, è
                            intermediario assicurativo dei prodotti di AXA MPS e opera anch’esso come
                            Titolare autonomo del trattamento per attività di identificazione e successivo
                            contatto, se richiesto da Lei e di identificazione (ovvero verificare se è già
                            cliente).<br/>
                            <br/>
                            <strong>
                                Per quali scopi e su quale
                                base i dati sono trattati?<br/>
                                Cosa comporta il rifiuto
                                di fornire i dati?
                            </strong><br/>
                            a) Finalità assicurativa<br/>
                            I dati anagrafici e di contatto sono trattati per gestire la Sua richiesta di
                            procedere
                            ad una quotazione e per attività statistiche ad essa correlate.<br/>
                            Saranno comunicati alla Banca solamente i suoi dati anagrafici, il recapito
                            telefonico e
                            il codice fiscale per la verifica della qualità di cliente della Banca.
                            Il trattamento dei dati è necessario per dare
                            esecuzione alle misure precontrattuali da Lei richieste, per adempiere obblighi di
                            legge, per legittimo interesse connesso all’attività assicurativa (attività
                            statistica).
                            Senza i dati non potranno essere forniti i servizi assicurativi.<br/><br/>
                            b) Finalità di contatto da parte della Banca<br/>
                            Qualora Lei richieda di essere contattato per fissare un appuntamento in una filiale
                            della Banca, la Compagnia provvede a comunicare a quest&#39;ultima i seguenti dati
                            personali a Lei riferiti: nome e cognome, codice fiscale (al fine di verificare se è
                            già
                            nostro cliente), CAP (per cercare la filiale a Lei più vicina nel caso non sia già
                            nostro
                            cliente) e telefono. La Banca non acquisisce alcuna informazione inerente al
                            preventivo della polizza che ha effettuato.<br/>
                            Il trattamento dei suddetti dati, da parte della Banca, risulta necessario per dar
                            seguito alla Sua richiesta di essere contattato; al riguardo Le precisiamo che per
                            tale finalità non è richiesto il Suo preventivo consenso dal momento che la base
                            giuridica che ne legittima il trattamento è la necessità di dar corso a Sue
                            specifiche richieste. In tal caso il conferimento dei dati non è obbligatorio ma un
                            eventuale rifiuto a fornirli, anche parzialmente, comporterebbe per la Banca
                            l’impossibilità di fornire il servizio richiesto.<br/>
                            <br/>
                            <strong>
                                A chi sono comunicati
                                i dati?
                            </strong><br/>
                            I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra
                            indicati:<br/>
                            - Dipendenti e collaboratori;<br/>
                            - Società terze che trattano i dati per conto del Titolare; in particolare, la Banca
                            nel caso in cui Lei chieda di essere contattato per fissare un appuntamento in una
                            filiale;<br/>
                            - Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella
                            gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori,
                            coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti
                            pubblici o associazioni di categoria;<br/>
                            - altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie
                            e di informazione commerciale per rischi finanziari<br/>
                            In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le
                            garanzie previste dalla vigente normativa.<br/>
                            <i>I dati non sono ceduti a terzi, né diffusi.</i><br/>
                            <br/>
                            <strong>
                                Quali sono i diritti dell’interessato
                                e come esercitarli?
                            </strong><br/>
                            Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il
                            blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere
                            la portabilità dei dati e far valere il diritto all’oblio, l’interessato può
                            scrivere a:<br/>
                            AXA MPS Assicurazioni Vita S.p.A. c.a. Data Protection Officer - Corso Como 17 –
                            20154 Milano (MI) - e-mail: <a href="mailto:privacy@axa.it">privacy@axa.it</a>.<br/>
                            Banca Monte dei paschi di Siena S.p.A. - Staff DPO e Conformità Privacy, Via A. Moro
                            n. 11/13 - 53100 Siena (fax + 39 0577 296520; e-mail: <a
                                href="mailto:privacy@mps.it">privacy@mps.it</a>).<br/>
                            Inoltre, potrà proporre reclamo al Garante Privacy (<a
                                href="https://www.garanteprivacy.it/"
                                target="_blank">https://www.garanteprivacy.it/</a>)<br/>
                            <br/>
                            <strong>
                                Da dove provengono
                                i dati trattati?
                            </strong><br/>
                            I dati sono raccolti da AXA MPS direttamente presso l’interessato o da terzi, anche
                            mediante la consultazione di banche dati.<br/>
                            La Banca acquisisce dalla Compagnia esclusivamente i dati personali sopra indicati
                            necessari per effettuare il contatto.<br/>
                            <br/>
                            <strong>
                                Per quanto tempo i dati vengono conservati?
                            </strong><br/>
                            I dati vengono conservati da AXA MPS per la finalità assicurativa come descritta nella
                            presente informativa al punto a) per non più di 24 mesi.<br/>
                            La Banca conserva i dati per il tempo necessario ad effettuare il contatto che viene
                            effettuato entro 60 giorni trascorso tale termine i dati sono cancellati<br/>
                            <br/>
                            <strong>
                                Informazioni aggiuntive
                            </strong><br/>
                            L’informativa completa è disponibile sul sito <a href="https://www.axa-mps.it/"
                                                                             target="_blank">www.axa-mps.it</a>.
                            Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione
                            Privacy del sito web indicato.<br />La presente informativa &egrave; aggiornata a Luglio 2020.<br/>
                            <br/>
                            <strong>
                                Responsabili della Protezione dei Dati
                            </strong><br/>
                            Banca Monte dei Paschi di Siena S.p.A e da AXA MPS Assicurazioni Danni S.p.A, hanno
                            istituito la figura del Responsabile della Protezione dei Dati (o Data Protection
                            Officer – DPO) prevista dalla normativa, affidandogli compiti di informazione e
                            consulenza per il titolare del trattamento in merito agli obblighi in materia di
                            protezione dei dati personali e di verifica dell’attuazione ed applicazione della
                            normativa.<br/>
                            I DPO possono essere contattati dagli interessati per tutte le questioni relative al
                            trattamento dei propri dati personali e per l’esercizio dei diritti previsti dal
                            GDPR tramite i seguenti recapiti:<br/>
                            - per AXA MPS Assicurazioni Danni S.p.A. – c.a. Data Protection Officer – Corso Como
                            17 – 20154 Milano – <a href="mailto:privacy@axa.it">privacy@axa.it</a><br/>
                            - per Banca Monte dei Paschi di Siena S.p.A. PEC: <a
                                href="mailto:responsabileprotezionedeidati@postacert.gruppo.mps.it">responsabileprotezionedeidati@postacert.gruppo.mps.it</a>;
                            e mail ordinaria <a href="mailto:responsabileprotezionedeidati@mps.it">responsabileprotezionedeidati@mps.it</a><br/>
                            <br/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title class="mt-5 v-card__footer py-0">
                    <v-col cols="12" class="text-center">
                        <div class="dialog__card__text text_container">
                            Per visualizzare l’informativa completa, <a href="https://www.axa-mps.it/privacy" target="_blank" class="privacy__link">Clicca qui</a>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center pb-0">
                        <v-btn tile text height="48" width="164"
                               @click="privacyDialog = false, privacy=true"
                               class="prospetto__invia pinkDark white--text">
                            HO CAPITO
                        </v-btn>
                    </v-col>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import DialogScrollable from "@/components/partials/DialogScrollable";
    import CodiceFiscale from "@/components/partials/CodiceFiscale";
    import Tooltip from "@/components/partials/Tooltip";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

    export default {
        name: "FormAppuntamento",
        components: {
            Tooltip,
            DialogScrollable,
            CodiceFiscale
        },
        computed: {
            ...mapGetters({
                'getInsuredData': 'params/getInsuredData',
            }),
        },
        watch: {},
        data: () => ({
            appuntamento: false,
            valid: false,
            privacy: false,
            inputRules: [
                v => !!v || 'Campo obbligatorio',
            ],
            name: '',
            lastName: '',
            telephone: '',
            telephoneRules: [
                v => !!v || 'Campo obbligatorio',
                v => /(^\+\d{11,12}$)|(^00\d{11,12}$)|(^\d{9,10}$)/.test(v) || 'Il formato del numero di telefono non è corretto',
                //v => /^([a-zA-Z0-9_-]){3,5}$/g.test(v) || 'Il formato del numero di telefono non è corretto',
            ],
            email: '',
            emailRules: [
                v => !!v || 'Campo obbligatorio',
                v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) || 'E-mail non valida',
            ],
            code: '',
            codeRules: [
                v => !!v || 'Campo obbligatorio',
                v => /^[a-zA-Z]{6}\d{2}[a-zA-Z]{1}\d{2}[a-zA-Z]{1}\d{3}[a-zA-Z]{1}$/.test(v) || 'Codice fiscale non valido',
            ],
            cont: 1,
            privacyDialog: false,
            idSession: sessionStorage.getItem('sessionId'),
        }),
        mounted() {
            this.name = this.getInsuredData.name;
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionAppointmentData': 'params/actionAppointmentData',
            }),
            checkPrivacy($event) {
                if (!this.privacy) {
                    //TODO: Fix orribile, da sistemare
                    this.$refs.field1.blur();
                    this.$refs.field2.blur();
                    this.$refs.field3.blur();
                    this.$refs.field4.blur();
                    this.$refs.field5.blur();
                    if ($event) {
                        this.$refs.appuntamento.resetValidation();
                    }
                    this.privacyDialog = true;
                }
            },
            codiceFisc(newCode) {
                this.code = newCode;
            },
        },
        updated() {
            if (this.consenso != '')
                this.checkPrivacy();

            if (this.name != '' && this.lastName != '' && this.telephone != '' && this.email != '' && this.code != '' && this.valid) {
                let data = {
                    name: this.name,
                    lastName: this.lastName,
                    telephone: this.telephone,
                    email: this.email,
                    code: this.code,
                };
                this.actionAppointmentData(data);
                this.actionIsDisable(false);
                this.actionIsFormValidate(true);
            } else {
                this.actionIsDisable(true);
            }
        },
    };
</script>
